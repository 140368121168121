export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: GraphQL_DateTime;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `ObjectId` scalar type represents a mongodb unique ID */
  ObjectId: string;
  /** The `StringObjectId` scalar type represents a string or mongodb unique ID */
  ObjectIdOrString: any;
  _FieldSet: any;
};

export enum ACTIVITY_TYPE {
  CANCEL_EVENT = "CANCEL_EVENT",
  CHANGE_DATE_EVENT = "CHANGE_DATE_EVENT",
  CHANGE_TASK_DUE_AT = "CHANGE_TASK_DUE_AT",
  CHILD_RELATION_CREATED = "CHILD_RELATION_CREATED",
  CREATED_CUSTOMER = "CREATED_CUSTOMER",
  CREATE_EVENT = "CREATE_EVENT",
  CREATE_SALE = "CREATE_SALE",
  CUSTOMER_ASSIGNED = "CUSTOMER_ASSIGNED",
  CUSTOMER_UNASSIGNED = "CUSTOMER_UNASSIGNED",
  DELETE_EVENT = "DELETE_EVENT",
  DELETE_ORDER = "DELETE_ORDER",
  DONE_EVENT = "DONE_EVENT",
  FILE_UPLOADED = "FILE_UPLOADED",
  MANUALLY_SIGN_SALE = "MANUALLY_SIGN_SALE",
  NOTE = "NOTE",
  ORDER_CREATED = "ORDER_CREATED",
  PARENT_RELATION_CREATED = "PARENT_RELATION_CREATED",
  REPLY_MESSAGE = "REPLY_MESSAGE",
  REQUEST_SALE_SIGNATURE = "REQUEST_SALE_SIGNATURE",
  SENT_MESSAGE = "SENT_MESSAGE",
  SIGNED_SALE = "SIGNED_SALE",
  TASK_ASSIGNED = "TASK_ASSIGNED",
  TASK_MOVED = "TASK_MOVED",
  TASK_UNASSIGNED = "TASK_UNASSIGNED",
  TIME_TRACKED = "TIME_TRACKED",
  UPDATE_CUSTOMER_STATUS = "UPDATE_CUSTOMER_STATUS",
  UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS",
  UPDATE_SALE = "UPDATE_SALE",
  UPDATE_SALE_EXPECTED_DATE = "UPDATE_SALE_EXPECTED_DATE",
  UPDATE_SALE_PROBABILITY = "UPDATE_SALE_PROBABILITY",
  UPDATE_SALE_STATUS = "UPDATE_SALE_STATUS",
  WON_SALE = "WON_SALE",
}

export type Ability = {
  __typename: "Ability";
  action: Maybe<Scalars["String"]>;
  conditions: Maybe<Scalars["String"]>;
  inverted: Scalars["Boolean"];
  subject: Maybe<Scalars["String"]>;
};

export type AbilityInput = {
  action: Scalars["String"];
  conditions?: InputMaybe<Scalars["String"]>;
  subject: Scalars["String"];
};

export type AbilityIntrospectionPayload = {
  __typename: "AbilityIntrospectionPayload";
  action: Scalars["String"];
  subject: Scalars["String"];
};

export type ActivationRequestInput = {
  callback: Scalars["String"];
  email: Scalars["String"];
  provider: CalendarProvider;
};

export type ActivationResponse = {
  __typename: "ActivationResponse";
  success: Scalars["Boolean"];
  url: Scalars["String"];
};

export type Activity = {
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type ActivityFilter = {
  between?: InputMaybe<ActivityFilterBetween>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  event?: InputMaybe<Scalars["ObjectId"]>;
  order?: InputMaybe<Scalars["ObjectId"]>;
  sale?: InputMaybe<Scalars["ObjectId"]>;
  type?: InputMaybe<Array<ACTIVITY_TYPE>>;
  user?: InputMaybe<Scalars["ObjectId"]>;
};

export type ActivityFilterBetween = {
  end: Scalars["DateTime"];
  start: Scalars["DateTime"];
};

export type AddEmailAddressToVerifyInput = {
  address: Scalars["String"];
};

export type AdministratorInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type AdvancedFilter = {
  __typename: "AdvancedFilter";
  _id: Scalars["ObjectId"];
  content: Scalars["JSON"];
  createdAt: Scalars["DateTime"];
  creator: User;
  deletedAt: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export type AdvancedFilterInput = {
  content: Scalars["JSON"];
  name: Scalars["String"];
};

export type Attendee = {
  __typename: "Attendee";
  email: Scalars["String"];
  name: Scalars["String"];
  response: AttendeeStatus;
};

export type AttendeeInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type AttendeeStatus = {
  __typename: "AttendeeStatus";
  status: Scalars["String"];
};

export type AuthenticationResponse = {
  __typename: "AuthenticationResponse";
  accessToken: Scalars["String"];
};

export type BackChannelAuthRequestNotification = Notification & {
  __typename: "BackChannelAuthRequestNotification";
  _id: Scalars["ObjectId"];
  authReqId: Scalars["String"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  fromOrganization: Organization;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  requester: User;
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type BasketInput = {
  currencyDiscount?: Scalars["Float"];
  currencyDiscountPercentage?: Scalars["Float"];
  currencyLinePrice?: Scalars["Float"];
  currencyOriginalPrice?: Scalars["Float"];
  currencyPrice?: Scalars["Float"];
  currencySubtotal?: Scalars["Float"];
  description?: InputMaybe<Scalars["String"]>;
  discount?: Scalars["Float"];
  discountPercentage?: Scalars["Float"];
  isExpiring?: Scalars["Boolean"];
  isPriceDynamic?: Scalars["Boolean"];
  isRecurring?: Scalars["Boolean"];
  linePrice?: Scalars["Float"];
  name: Scalars["String"];
  originalPrice?: Scalars["Float"];
  period?: InputMaybe<DateRange>;
  price?: Scalars["Float"];
  product: Scalars["ObjectId"];
  qty: Scalars["Float"];
  recurringLength?: InputMaybe<Scalars["Float"]>;
  recurringUnit?: InputMaybe<ProductDurationUnit>;
  sku?: InputMaybe<Scalars["String"]>;
  subtotal?: Scalars["Float"];
  unit: Scalars["String"];
};

export type BasketItem = {
  __typename: "BasketItem";
  currencyDiscount: Scalars["Float"];
  currencyDiscountPercentage: Scalars["Float"];
  currencyLinePrice: Scalars["Float"];
  currencyOriginalPrice: Scalars["Float"];
  currencyPrice: Scalars["Float"];
  currencySubtotal: Scalars["Float"];
  description: Maybe<Scalars["String"]>;
  discount: Scalars["Float"];
  discountPercentage: Scalars["Float"];
  isExpiring: Scalars["Boolean"];
  isPriceDynamic: Scalars["Boolean"];
  isRecurring: Scalars["Boolean"];
  linePrice: Scalars["Float"];
  name: Scalars["String"];
  originalPrice: Scalars["Float"];
  period: Maybe<DateRangeType>;
  price: Scalars["Float"];
  product: Product;
  qty: Scalars["Float"];
  recurringLength: Maybe<Scalars["Float"]>;
  recurringUnit: Maybe<ProductDurationUnit>;
  sku: Maybe<Scalars["String"]>;
  subtotal: Scalars["Float"];
  unit: Scalars["String"];
};

export type Billing = {
  __typename: "Billing";
  address: Maybe<Scalars["String"]>;
  channel: Maybe<BillingChannel>;
  channelReference: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  county: Maybe<Scalars["String"]>;
  email: Maybe<Scalars["String"]>;
  municipality: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  reference: Maybe<Scalars["String"]>;
  zip: Maybe<Scalars["String"]>;
};

export type BillingAddressInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  sameAsVisitor?: InputMaybe<Scalars["Boolean"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type BillingAddressType = {
  __typename: "BillingAddressType";
  address: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  county: Maybe<Scalars["String"]>;
  sameAsVisitor: Maybe<Scalars["Boolean"]>;
  zip: Maybe<Scalars["String"]>;
};

export enum BillingChannel {
  ehf = "ehf",
  email = "email",
  paper = "paper",
  vipps = "vipps",
}

export type BillingDetails = {
  __typename: "BillingDetails";
  currentPeriod: Maybe<Scalars["JSON"]>;
  id: Scalars["String"];
  items: Array<Item>;
  payment: PaymentMethod;
  trialPeriod: Maybe<Scalars["JSON"]>;
};

export enum BillingFrequency {
  bimonthly = "bimonthly",
  monthly = "monthly",
  quarterly = "quarterly",
  semiannually = "semiannually",
  yearly = "yearly",
}

export type BillingInput = {
  address?: InputMaybe<Scalars["String"]>;
  channel?: InputMaybe<BillingChannel>;
  channelReference?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  reference?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export enum BillingInterval {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum BillingMode {
  SUBSCRIPTION = "SUBSCRIPTION",
}

export type BillingProviderConfig = {
  __typename: "BillingProviderConfig";
  cancelUrl: Scalars["String"];
  publicKey: Scalars["String"];
  successUrl: Scalars["String"];
};

export enum BillingScheme {
  PER_UNIT = "PER_UNIT",
  TIERED = "TIERED",
}

export enum BillingStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  PAUSED = "PAUSED",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID",
}

export type BrregResult = {
  __typename: "BrregResult";
  _id: Scalars["String"];
  address: Maybe<Scalars["String"]>;
  business: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  orgnr: Scalars["String"];
  zip: Maybe<Scalars["String"]>;
};

export type Calendar = {
  __typename: "Calendar";
  _id: Scalars["ObjectIdOrString"];
  account: Maybe<CalendarAccount>;
  backgroundColor: Maybe<Scalars["String"]>;
  canEdit: Scalars["Boolean"];
  description: Maybe<Scalars["String"]>;
  foregroundColor: Maybe<Scalars["String"]>;
  provider: CalendarProvider;
  title: Scalars["String"];
};

export type CalendarAccount = {
  __typename: "CalendarAccount";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  creator: Scalars["ObjectId"];
  kind: IntegrationKind;
  organization: Scalars["ObjectId"];
  payload: CalendarAccountPayload;
  status: IntegrationStatus;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Scalars["ObjectId"];
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type CalendarAccountPayload = {
  __typename: "CalendarAccountPayload";
  accountId: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  provider: CalendarProvider;
};

export type CalendarIntegration = {
  __typename: "CalendarIntegration";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  creator: Scalars["ObjectId"];
  kind: IntegrationKind;
  organization: Scalars["ObjectId"];
  payload: CalendarAccountPayload;
  status: IntegrationStatus;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Scalars["ObjectId"];
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type CalendarIntegrationFilters = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<IntegrationStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  userUpdatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export enum CalendarProvider {
  AIFLOW = "AIFLOW",
  GOOGLE = "GOOGLE",
  MICROSOFT = "MICROSOFT",
}

export type CalendarSettings = {
  __typename: "CalendarSettings";
  _id: Maybe<Scalars["ObjectId"]>;
  area: Maybe<EntityName>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  domain: Maybe<EntityName>;
  payload: CalendarSettingsPayload;
  resource: Maybe<Scalars["ObjectId"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<Scalars["ObjectId"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type CalendarSettingsPayload = {
  __typename: "CalendarSettingsPayload";
  fields: Maybe<Array<Scalars["String"]>>;
  host: Maybe<Array<Scalars["ObjectId"]>>;
  isCancelled: Maybe<Scalars["Boolean"]>;
  isDone: Maybe<Scalars["Boolean"]>;
  mode: Maybe<Scalars["String"]>;
  type: Maybe<Array<Scalars["ObjectId"]>>;
  weekends: Maybe<Scalars["Boolean"]>;
};

export type CalendarSettingsPayloadInput = {
  fields?: InputMaybe<Array<Scalars["String"]>>;
  host?: InputMaybe<Array<Scalars["ObjectId"]>>;
  isCancelled?: InputMaybe<Scalars["Boolean"]>;
  isDone?: InputMaybe<Scalars["Boolean"]>;
  mode?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Array<Scalars["ObjectId"]>>;
  weekends?: InputMaybe<Scalars["Boolean"]>;
};

export type Card = {
  __typename: "Card";
  brand: Scalars["String"];
  expMonth: Scalars["Float"];
  expYear: Scalars["Float"];
  last4: Scalars["String"];
};

export type Checkout = {
  __typename: "Checkout";
  email: Maybe<Scalars["String"]>;
  numberOfSeats: Maybe<Scalars["Float"]>;
  organization: Organization;
  priceId: Maybe<Scalars["String"]>;
  trial: Maybe<Scalars["Boolean"]>;
};

export type CheckoutInput = {
  email?: InputMaybe<Scalars["String"]>;
  numberOfSeats?: InputMaybe<Scalars["Float"]>;
  priceId?: InputMaybe<Scalars["String"]>;
  trial?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutSession = {
  __typename: "CheckoutSession";
  clientSecret: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  organization: Scalars["ObjectId"];
  status: BillingStatus;
};

export type CommunicationSettingsInput = {
  defaultAddress?: InputMaybe<Scalars["String"]>;
};

export type CommunicationSettingsType = {
  __typename: "CommunicationSettingsType";
  defaultAddress: Scalars["String"];
};

export type ConferenceData = {
  __typename: "ConferenceData";
  joinUrl: Scalars["String"];
};

export type Contract = {
  __typename: "Contract";
  _id: Scalars["ObjectId"];
  content: Maybe<Scalars["JSON"]>;
  createdAt: Scalars["DateTime"];
  creator: User;
  deletedAt: Maybe<Scalars["DateTime"]>;
  description: Maybe<Scalars["String"]>;
  legacy: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export type ContractInput = {
  content: Scalars["JSON"];
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ContractSettingsInput = {
  defaultContract?: InputMaybe<Scalars["ObjectId"]>;
  subtotal?: InputMaybe<Scalars["Boolean"]>;
  summaryPlacement?: InputMaybe<Scalars["String"]>;
};

export type ContractSettingsType = {
  __typename: "ContractSettingsType";
  cartSummary: Scalars["Boolean"];
  defaultContract: Maybe<Contract>;
  subtotal: Scalars["Boolean"];
  summaryPlacement: Scalars["String"];
};

export type Conversation = {
  __typename: "Conversation";
  _id: Scalars["ObjectId"];
  assigned: Array<User>;
  createdAt: Scalars["DateTime"];
  creator: User;
  customer: Maybe<Customer>;
  isForwarded: Scalars["Boolean"];
  isRegistered: Scalars["Boolean"];
  latestRecipients: ConversationRecipients;
  messages: Array<ConversationMessage>;
  messagesCount: Scalars["Float"];
  preview: Maybe<Scalars["String"]>;
  subject: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export type ConversationmessagesArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type ConversationAttachments = {
  __typename: "ConversationAttachments";
  _id: Scalars["ObjectId"];
  contentType: Scalars["String"];
  isInline: Maybe<Scalars["Boolean"]>;
  name: Maybe<Scalars["String"]>;
  size: Maybe<Scalars["Float"]>;
};

export type ConversationMessage = ConversationRecipientsInterface & {
  __typename: "ConversationMessage";
  _id: Scalars["ObjectId"];
  attachments: Array<ConversationAttachments>;
  bcc: Array<ConversationRecipient>;
  cc: Array<ConversationRecipient>;
  content: Scalars["String"];
  conversation: Conversation;
  createdAt: Maybe<Scalars["DateTime"]>;
  from: ConversationRecipient;
  isDraft: Scalars["Boolean"];
  isForwarded: Scalars["Boolean"];
  isInbound: Scalars["Boolean"];
  isRead: Scalars["Boolean"];
  isRegistered: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  preview: Scalars["String"];
  sentAt: Maybe<Scalars["DateTime"]>;
  subject: Maybe<Scalars["String"]>;
  to: Array<ConversationRecipient>;
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export type ConversationRecipient = {
  __typename: "ConversationRecipient";
  address: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  person: Maybe<Person>;
  user: Maybe<User>;
};

export type ConversationRecipients = ConversationRecipientsInterface & {
  __typename: "ConversationRecipients";
  bcc: Array<ConversationRecipient>;
  cc: Array<ConversationRecipient>;
  from: ConversationRecipient;
  to: Array<ConversationRecipient>;
};

export type ConversationRecipientsInterface = {
  bcc: Array<ConversationRecipient>;
  cc: Array<ConversationRecipient>;
  from: ConversationRecipient;
  to: Array<ConversationRecipient>;
};

export enum CountCustomersBy {
  unique = "unique",
}

export enum CountEventsBy {
  distinct = "distinct",
  time = "time",
  unique = "unique",
}

export enum CountEventsDistinctBy {
  distinct = "distinct",
  time = "time",
  unique = "unique",
}

export enum CountSalesBy {
  distinct = "distinct",
  qty = "qty",
  subtotal = "subtotal",
  unique = "unique",
}

export type CreateActivityInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  message?: InputMaybe<Scalars["ObjectId"]>;
  note: Scalars["String"];
  order?: InputMaybe<Scalars["ObjectId"]>;
  sale?: InputMaybe<Scalars["ObjectId"]>;
  type?: InputMaybe<ACTIVITY_TYPE>;
  user?: InputMaybe<Scalars["ObjectId"]>;
};

export type CreateCustomerStatus = {
  color?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateDashboardInput = {
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  grid?: InputMaybe<Array<GridInput>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type CreateEventInput = {
  attendees?: InputMaybe<Array<AttendeeInput>>;
  calendar?: InputMaybe<Scalars["ObjectIdOrString"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]>;
  comment?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  description?: InputMaybe<Scalars["String"]>;
  doneAt?: InputMaybe<Scalars["DateTime"]>;
  end: Scalars["DateTime"];
  eventType?: InputMaybe<Scalars["ObjectId"]>;
  host?: InputMaybe<Array<Scalars["ObjectId"]>>;
  meta?: InputMaybe<MetaInput>;
  participants?: InputMaybe<Array<Scalars["ObjectId"]>>;
  start: Scalars["DateTime"];
  title?: InputMaybe<Scalars["String"]>;
};

export type CreateEventType = {
  borderColor: EventTypesBorderColor;
  color: EventTypesColor;
  name: Scalars["String"];
  type: EventTypes;
};

export type CreateEventsGraphFilter = {
  __typename: "CreateEventsGraphFilter";
  period: Maybe<Period>;
};

export type CreateOrganizationInput = {
  address: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
  county: Scalars["String"];
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  industry?: InputMaybe<Scalars["String"]>;
  maxMembers?: Scalars["Float"];
  meta?: InputMaybe<OrganizationMetaInput>;
  modules?: Array<Modules>;
  municipality: Scalars["String"];
  name: Scalars["String"];
  orgnr: Scalars["String"];
  slug?: InputMaybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type CreateProductInput = {
  creator?: InputMaybe<Scalars["ObjectId"]>;
  description?: InputMaybe<Scalars["String"]>;
  hidden?: Scalars["Boolean"];
  isExpiring?: Scalars["Boolean"];
  isPriceDynamic?: Scalars["Boolean"];
  isRecurring?: Scalars["Boolean"];
  name: Scalars["String"];
  pricing: Array<ProductPriceInput>;
  recurringLength?: InputMaybe<Scalars["Float"]>;
  recurringUnit?: InputMaybe<ProductDurationUnit>;
  sku?: InputMaybe<Scalars["String"]>;
  unit: Scalars["String"];
};

export type CreateTenantInput = {
  administrator: AdministratorInput;
  organization: CreateOrganizationInput;
};

export enum Currency {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ARS = "ARS",
  AUD = "AUD",
  AZN = "AZN",
  BAM = "BAM",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GNF = "GNF",
  GTQ = "GTQ",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KHR = "KHR",
  KMF = "KMF",
  KRW = "KRW",
  KWD = "KWD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MOP = "MOP",
  MUR = "MUR",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SOS = "SOS",
  SYP = "SYP",
  THB = "THB",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  XAF = "XAF",
  XOF = "XOF",
  YER = "YER",
  ZAR = "ZAR",
  ZMK = "ZMK",
  ZWL = "ZWL",
}

export type CustomField = {
  __typename: "CustomField";
  _id: Scalars["String"];
  createdAt: Maybe<Scalars["DateTime"]>;
  createdBy: Maybe<User>;
  description: Maybe<Scalars["String"]>;
  domain: EntityName;
  name: Scalars["String"];
  options: Maybe<CustomFieldOptions>;
  position: Maybe<Scalars["String"]>;
  required: Scalars["Boolean"];
  type: FieldType;
  updatedAt: Maybe<Scalars["DateTime"]>;
  updatedBy: Maybe<User>;
};

export type CustomFieldInput = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  options?: InputMaybe<CustomFieldOptionsInput>;
  required?: InputMaybe<Scalars["Boolean"]>;
  type: FieldType;
};

export type CustomFieldOptions = {
  __typename: "CustomFieldOptions";
  area: Maybe<EntityName>;
  multiple: Maybe<Scalars["Boolean"]>;
  values: Maybe<Array<Scalars["String"]>>;
};

export type CustomFieldOptionsInput = {
  area?: InputMaybe<EntityName>;
  multiple?: InputMaybe<Scalars["Boolean"]>;
  values?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomFieldValueToMigrateInput = {
  migrateTo: Scalars["String"];
  value: Scalars["String"];
};

export type Customer = {
  __typename: "Customer";
  _id: Scalars["ObjectId"];
  activity: Maybe<Array<Scalars["ObjectId"]>>;
  address: Maybe<Scalars["String"]>;
  assigned: Maybe<Array<User>>;
  billing: Maybe<Billing>;
  business: Scalars["String"];
  category: Maybe<Array<CustomerCategory>>;
  childCompaniesCount: Maybe<Scalars["Int"]>;
  city: Maybe<Scalars["String"]>;
  contract: Maybe<Contract>;
  conversations: Maybe<Array<Conversation>>;
  countSales: Maybe<Scalars["Int"]>;
  country: Maybe<Scalars["String"]>;
  county: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  creator: Maybe<User>;
  customerNr: Maybe<Scalars["String"]>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<Scalars["ObjectId"]>;
  deliveryAddresses: Maybe<Array<DeliveryAddressType>>;
  events: Maybe<Array<Event>>;
  forwardAddress: Maybe<Scalars["String"]>;
  lists: Array<List>;
  lostAt: Maybe<Scalars["DateTime"]>;
  mainPerson: Maybe<Person>;
  meta: Maybe<Meta>;
  municipality: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  nickname: Maybe<Scalars["String"]>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars["ObjectId"]>;
  orgnr: Maybe<Scalars["String"]>;
  people: Maybe<Array<Person>>;
  referrals: Maybe<Array<Scalars["ObjectId"]>>;
  referrer: Maybe<Scalars["ObjectId"]>;
  relationType: Maybe<RelationType>;
  sales: Maybe<Array<Sale>>;
  startedAt: Maybe<Scalars["DateTime"]>;
  status: Maybe<CustomerStatus>;
  targetUnit: Maybe<Customer>;
  type: Maybe<CustomerType>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  userUpdatedAt: Scalars["DateTime"];
  wontAt: Maybe<Scalars["DateTime"]>;
  zip: Maybe<Scalars["String"]>;
};

export type CustomerconversationsArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type CustomercountSalesArgs = {
  countBy: CountSalesBy;
  filter?: InputMaybe<SaleFilter>;
  filterId?: InputMaybe<Scalars["ObjectId"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  search?: InputMaybe<Scalars["String"]>;
};

export type CustomereventsArgs = {
  filter?: InputMaybe<EventFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type CustomerActivity = Activity & {
  __typename: "CustomerActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customUser: Maybe<User>;
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  next: Maybe<CustomerStatus>;
  prev: Maybe<CustomerStatus>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type CustomerBillingAddressInput = {
  address?: InputMaybe<Scalars["String"]>;
  channel?: InputMaybe<BillingChannel>;
  channelReference?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  reference?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CustomerCategory = {
  __typename: "CustomerCategory";
  _id: Scalars["ObjectId"];
  assigned: Maybe<Array<User>>;
  description: Maybe<Scalars["String"]>;
  disabled: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  order: Maybe<Scalars["Float"]>;
  updatedBy: Maybe<User>;
};

export type CustomerCategoryFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type CustomerCategoryInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  order?: InputMaybe<Scalars["Float"]>;
};

export type CustomerFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  between?: InputMaybe<CustomerFilterBetweenInput>;
  category?: InputMaybe<Array<Scalars["ObjectId"]>>;
  city?: InputMaybe<Scalars["String"]>;
  conversations?: InputMaybe<CustomerFilterConversationInput>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<DateRange>;
  creator?: InputMaybe<Array<Scalars["ObjectId"]>>;
  customerNr?: InputMaybe<Scalars["String"]>;
  events?: InputMaybe<CustomerFilterEventsInput>;
  lastActivity?: InputMaybe<DateRange>;
  municipality?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  orgnr?: InputMaybe<Scalars["String"]>;
  people?: InputMaybe<CustomerFilterPeopleInput>;
  sales?: InputMaybe<CustomerFilterSalesInput>;
  status?: InputMaybe<Array<Scalars["String"]>>;
  userUpdatedAt?: InputMaybe<DateRange>;
};

export type CustomerFilterBetweenInput = {
  end: Scalars["String"];
  property?: InputMaybe<CustomerFilterBetweenProperty>;
  start: Scalars["String"];
};

export enum CustomerFilterBetweenProperty {
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type CustomerFilterConversationInput = {
  createdAt?: InputMaybe<CustomerFilterBetweenInput>;
  subject?: InputMaybe<Scalars["String"]>;
};

export type CustomerFilterEventsInput = {
  date?: InputMaybe<DateRange>;
  email?: InputMaybe<Scalars["Boolean"]>;
  phone?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerFilterPeopleInput = {
  email?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["Boolean"]>;
  role?: InputMaybe<Scalars["String"]>;
};

export type CustomerFilterSalesInput = {
  basketOriginalPrice?: InputMaybe<NumberRangeInput>;
  basketPrice?: InputMaybe<NumberRangeInput>;
  basketQty?: InputMaybe<NumberRangeInput>;
  createdAt?: InputMaybe<DateRange>;
  price?: InputMaybe<NumberRangeInput>;
  products?: InputMaybe<Array<Scalars["ObjectId"]>>;
  status?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerImportInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  address?: InputMaybe<Scalars["String"]>;
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  billing?: InputMaybe<BillingInput>;
  business?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Array<Scalars["String"]>>;
  city?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["ObjectId"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  creator?: InputMaybe<Scalars["ObjectId"]>;
  customerNr?: InputMaybe<Scalars["String"]>;
  deliveryAddresses?: InputMaybe<Array<DeliveryAddressInput>>;
  meta?: InputMaybe<MetaInput>;
  municipality?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orgnr?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  type?: CustomerType;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CustomerInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  address?: InputMaybe<Scalars["String"]>;
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  billing?: InputMaybe<BillingInput>;
  business?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Array<Scalars["ObjectId"]>>;
  city?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["ObjectId"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  creator?: InputMaybe<Scalars["ObjectId"]>;
  customerNr?: InputMaybe<Scalars["String"]>;
  meta?: InputMaybe<MetaInput>;
  municipality?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nickname?: InputMaybe<Scalars["String"]>;
  orgnr?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationType>;
  status?: InputMaybe<Scalars["ObjectId"]>;
  targetUnit?: InputMaybe<Scalars["ObjectId"]>;
  type?: CustomerType;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CustomerSection = {
  __typename: "CustomerSection";
  by: TASK_SECTIONING_OPTIONS;
  count: Scalars["Float"];
  sections: Array<CustomerSectionMetadata>;
};

export type CustomerSectionMetadata = {
  __typename: "CustomerSectionMetadata";
  count: Scalars["Float"];
  payload: Customer;
};

export type CustomerSettings = {
  __typename: "CustomerSettings";
  _id: Maybe<Scalars["ObjectId"]>;
  area: Maybe<EntityName>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  domain: Maybe<EntityName>;
  payload: CustomerSettingsPayload;
  resource: Maybe<Scalars["ObjectId"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<Scalars["ObjectId"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type CustomerSettingsInput = {
  types?: InputMaybe<CustomerTypesSettingsInput>;
};

export type CustomerSettingsPayload = {
  __typename: "CustomerSettingsPayload";
  displayQuickActions: Scalars["Boolean"];
};

export type CustomerSettingsType = {
  __typename: "CustomerSettingsType";
  types: CustomerTypes;
};

export type CustomerStatus = {
  __typename: "CustomerStatus";
  _id: Scalars["ObjectId"];
  color: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  name: Scalars["String"];
  order: Maybe<Scalars["Float"]>;
  updatedBy: Maybe<Scalars["ObjectId"]>;
};

export type CustomerStatusFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type CustomerStatusesInput = {
  statuses: Array<UpdateCustomerStatus>;
};

export enum CustomerType {
  company = "company",
  private = "private",
}

export type CustomerTypes = {
  __typename: "CustomerTypes";
  company: Scalars["Boolean"];
  private: Scalars["Boolean"];
};

export type CustomerTypesSettingsInput = {
  company?: InputMaybe<Scalars["Boolean"]>;
  private?: InputMaybe<Scalars["Boolean"]>;
};

export type Dashboard = {
  __typename: "Dashboard";
  _id: Scalars["ObjectId"];
  assigned: Array<Scalars["ObjectId"]>;
  creator: Maybe<User>;
  grid: Maybe<Array<GridItem>>;
  name: Scalars["String"];
};

export enum DataType {
  OnboardingData = "OnboardingData",
}

export type DateRange = {
  end?: InputMaybe<Scalars["DateTime"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type DateRangeString = {
  end?: InputMaybe<Scalars["String"]>;
  start?: InputMaybe<Scalars["String"]>;
};

export type DateRangeType = {
  __typename: "DateRangeType";
  end: Maybe<Scalars["DateTime"]>;
  start: Maybe<Scalars["DateTime"]>;
};

export type DateSection = {
  __typename: "DateSection";
  by: TASK_SECTIONING_OPTIONS;
  count: Scalars["Float"];
  sections: Array<DateSectionMetadata>;
};

export type DateSectionMetadata = {
  __typename: "DateSectionMetadata";
  count: Scalars["Float"];
  payload: Scalars["DateTime"];
};

export type DealsFilter = {
  __typename: "DealsFilter";
  period: Maybe<Period>;
};

export type DefaultPaginateArgs = {
  __typename: "DefaultPaginateArgs";
  fuzzy: Maybe<Scalars["Boolean"]>;
  limit: Maybe<Scalars["Int"]>;
  nextPageToken: Maybe<Scalars["String"]>;
  order: Maybe<Scalars["Int"]>;
  search: Maybe<Scalars["String"]>;
  skip: Maybe<Scalars["Int"]>;
  sortBy: Maybe<Scalars["String"]>;
};

export type DefaultSharedResourceUninonType = {
  __typename: "DefaultSharedResourceUninonType";
  _id: Scalars["ObjectId"];
};

export type DeliveryAddressInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type DeliveryAddressType = {
  __typename: "DeliveryAddressType";
  _id: Maybe<Scalars["ObjectId"]>;
  address: Scalars["String"];
  city: Scalars["String"];
  country: Scalars["String"];
  county: Scalars["String"];
  isDefault: Scalars["Boolean"];
  municipality: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export enum EXPORTABLE_KIND {
  DYNAMIC = "DYNAMIC",
  STATIC = "STATIC",
}

export enum EntityName {
  ACTIVITY = "ACTIVITY",
  CALENDAR = "CALENDAR",
  CONVERSATION = "CONVERSATION",
  CONVERSATION_MESSAGE = "CONVERSATION_MESSAGE",
  CONVERSATION_TEMPLATE = "CONVERSATION_TEMPLATE",
  COUNTER = "COUNTER",
  CUSTOMER = "CUSTOMER",
  CUSTOMER_CATEGORY = "CUSTOMER_CATEGORY",
  CUSTOMER_STATUS = "CUSTOMER_STATUS",
  CUSTOM_FIELD = "CUSTOM_FIELD",
  DASHBOARD = "DASHBOARD",
  EVENT = "EVENT",
  EVENT_TYPE = "EVENT_TYPE",
  EXPORTABLE = "EXPORTABLE",
  FILE = "FILE",
  FLAG = "FLAG",
  INDUSTRY_TYPE = "INDUSTRY_TYPE",
  INTEGRATION = "INTEGRATION",
  LIST = "LIST",
  LOCKED_EMAILS = "LOCKED_EMAILS",
  MEMBER = "MEMBER",
  NOTIFICATION = "NOTIFICATION",
  OIDC = "OIDC",
  OIDC_CLIENT = "OIDC_CLIENT",
  ORDER = "ORDER",
  ORDER_TASK = "ORDER_TASK",
  ORGANIZATION = "ORGANIZATION",
  ORGANIZATION_BILLING = "ORGANIZATION_BILLING",
  ORGANIZATION_CHECKOUT = "ORGANIZATION_CHECKOUT",
  PERSON = "PERSON",
  PRODUCT = "PRODUCT",
  ROLE = "ROLE",
  SALE = "SALE",
  SETTINGS = "SETTINGS",
  SIGNATURE = "SIGNATURE",
  STEP = "STEP",
  TASK = "TASK",
  TEMPLATE = "TEMPLATE",
  TIME_TRACKING = "TIME_TRACKING",
  USER = "USER",
  WHITELIST = "WHITELIST",
  WORKFLOW = "WORKFLOW",
  WORKFLOW_STEP = "WORKFLOW_STEP",
}

export type Event = {
  __typename: "Event";
  _id: Scalars["ObjectIdOrString"];
  attendees: Maybe<Array<Attendee>>;
  calendar: Calendar;
  cancelledAt: Maybe<Scalars["DateTime"]>;
  comment: Maybe<Scalars["String"]>;
  conferenceData: Maybe<ConferenceData>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  description: Maybe<Scalars["String"]>;
  doneAt: Maybe<Scalars["DateTime"]>;
  end: Maybe<Scalars["DateTime"]>;
  eventType: Maybe<EventType>;
  host: Maybe<Array<User>>;
  isConference: Scalars["Boolean"];
  isOrganizer: Scalars["Boolean"];
  lists: Maybe<Array<List>>;
  meta: Maybe<Meta>;
  participants: Maybe<Array<Person>>;
  start: Maybe<Scalars["DateTime"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type EventActivity = Activity & {
  __typename: "EventActivity";
  _id: Scalars["ObjectId"];
  cancelledAt: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  doneAt: Maybe<Scalars["DateTime"]>;
  end: Scalars["DateTime"];
  event: Event;
  eventType: EventType;
  host: Maybe<Array<User>>;
  next: Maybe<Scalars["DateTime"]>;
  prev: Maybe<Scalars["DateTime"]>;
  start: Scalars["DateTime"];
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type EventFilter = {
  between?: InputMaybe<EventFilterBetween>;
  calendar?: InputMaybe<Scalars["ObjectId"]>;
  creator?: InputMaybe<Array<Scalars["ObjectId"]>>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  host?: InputMaybe<Array<Scalars["ObjectId"]>>;
  isCancelled?: InputMaybe<Scalars["Boolean"]>;
  isDone?: InputMaybe<Scalars["Boolean"]>;
  isFuture?: InputMaybe<Scalars["Boolean"]>;
  isInteracted?: InputMaybe<EventFilterInteracted>;
  isToday?: InputMaybe<Scalars["Boolean"]>;
  propertyExists?: InputMaybe<Array<Scalars["String"]>>;
  type?: InputMaybe<Array<Scalars["ObjectId"]>>;
  typeName?: InputMaybe<Array<Scalars["String"]>>;
};

export type EventFilterBetween = {
  end: Scalars["String"];
  property?: InputMaybe<EventFilterBetweenProperty>;
  start: Scalars["String"];
};

export enum EventFilterBetweenProperty {
  cancelledAt = "cancelledAt",
  createdAt = "createdAt",
  end = "end",
  start = "start",
  updatedAt = "updatedAt",
}

export type EventFilterInteracted = {
  isCancelled?: InputMaybe<Scalars["Boolean"]>;
  isDone?: InputMaybe<Scalars["Boolean"]>;
};

export type EventNotification = Notification & {
  __typename: "EventNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  event: Event;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type EventType = {
  __typename: "EventType";
  _id: Scalars["ObjectId"];
  borderColor: EventTypesBorderColor;
  color: EventTypesColor;
  name: Scalars["String"];
  order: Maybe<Scalars["Float"]>;
  type: EventTypes;
  updatedBy: Maybe<Scalars["ObjectId"]>;
};

export type EventTypeFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export enum EventTypes {
  customerRetention = "customerRetention",
  finance = "finance",
  internal = "internal",
  order = "order",
  other = "other",
  planning = "planning",
  private = "private",
  production = "production",
  prospecting = "prospecting",
  sales = "sales",
  service = "service",
  support = "support",
  training = "training",
}

export enum EventTypesBorderColor {
  additional1 = "additional1",
  additional2 = "additional2",
  additional3 = "additional3",
  additional4 = "additional4",
  customerRetention = "customerRetention",
  finance = "finance",
  internal = "internal",
  order = "order",
  other = "other",
  planning = "planning",
  private = "private",
  production = "production",
  prospecting = "prospecting",
  sales = "sales",
  service = "service",
  support = "support",
  training = "training",
}

export enum EventTypesColor {
  additional1 = "additional1",
  additional2 = "additional2",
  additional3 = "additional3",
  additional4 = "additional4",
  customerRetention = "customerRetention",
  finance = "finance",
  internal = "internal",
  order = "order",
  other = "other",
  planning = "planning",
  private = "private",
  production = "production",
  prospecting = "prospecting",
  sales = "sales",
  service = "service",
  support = "support",
  training = "training",
}

export type EventTypesInput = {
  eventTypes: Array<UpdateEventType>;
};

export type ExpiringContractNotification = Notification & {
  __typename: "ExpiringContractNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  sale: Sale;
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type Exportable = {
  __typename: "Exportable";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  domain: EntityName;
  kind: EXPORTABLE_KIND;
  options: DefaultPaginateArgs;
  payload: FilterArgs;
  status: ExportableStatus;
};

export type ExportableNotification = Notification & {
  __typename: "ExportableNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  exportable: Exportable;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export enum ExportableStatus {
  COMPLETED = "COMPLETED",
  EMPTY = "EMPTY",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  REGISTERED = "REGISTERED",
}

export type FallbackMapInput = {
  from: Scalars["ObjectId"];
  to: Scalars["ObjectId"];
};

export type FallbackWorkflowMapInput = {
  steps: Array<FallbackMapInput>;
  workflow: FallbackMapInput;
};

export enum FieldType {
  CHECKBOX = "CHECKBOX",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  DATE_RANGE = "DATE_RANGE",
  ID = "ID",
  NUMBER = "NUMBER",
  RATING = "RATING",
  SELECT = "SELECT",
  TEXT = "TEXT",
  TEXTBOX = "TEXTBOX",
  TIME = "TIME",
  TIME_RANGE = "TIME_RANGE",
}

export type File = {
  __typename: "File";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  creator: User;
  customer: Customer;
  filetype: Scalars["String"];
  name: Scalars["String"];
  order: Maybe<Order>;
  sale: Maybe<Sale>;
  size: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
  url: Scalars["String"];
};

export type FileActivity = Activity & {
  __typename: "FileActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  file: File;
  name: Scalars["String"];
  order: Maybe<Order>;
  sale: Maybe<Sale>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type FilterArgs = {
  __typename: "FilterArgs";
  and: Maybe<Array<FilterUnitArgs>>;
  filter: Maybe<NestedFilterArgs>;
  not: Maybe<Array<FilterUnitArgs>>;
  or: Maybe<Array<FilterUnitArgs>>;
};

export type FilterArgsInput = {
  and?: InputMaybe<Array<FilterUnitArgsInput>>;
  filter?: InputMaybe<NestedFilterArgsInput>;
  not?: InputMaybe<Array<FilterUnitArgsInput>>;
  or?: InputMaybe<Array<FilterUnitArgsInput>>;
};

export enum FilterType {
  CreateEventsGraphFilter = "CreateEventsGraphFilter",
  DealsFilter = "DealsFilter",
  OnboardingFilter = "OnboardingFilter",
  RealtimeActivityFilter = "RealtimeActivityFilter",
  SalePipelineFilter = "SalePipelineFilter",
  SalesGraphFilter = "SalesGraphFilter",
}

export type FilterUnitArgs = {
  __typename: "FilterUnitArgs";
  operator: FilterUnitOperator;
  path: Maybe<Scalars["String"]>;
  value: Maybe<Scalars["JSON"]>;
};

export type FilterUnitArgsInput = {
  operator: FilterUnitOperator;
  path?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["JSON"]>;
};

export enum FilterUnitOperator {
  between = "between",
  compound = "compound",
  equals = "equals",
  exists = "exists",
  like = "like",
}

export type Flag = {
  __typename: "Flag";
  _id: Scalars["String"];
  createdAt: Maybe<Scalars["DateTime"]>;
  createdBy: Maybe<User>;
  domain: EntityName;
  kind: FlagKind;
  resource: Maybe<FlagResourceUnion>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  updatedBy: Maybe<User>;
};

export type FlagCreateInput = {
  domain: EntityName;
  kind: FlagKind;
  resource: Scalars["ObjectId"];
};

export enum FlagKind {
  FAVORITE = "FAVORITE",
}

export type FlagResourceUnion =
  | Conversation
  | ConversationMessage
  | CustomField
  | Customer
  | CustomerCategory
  | CustomerStatus
  | Event
  | EventType
  | File
  | List
  | Order
  | OrderTask
  | Organization
  | Person
  | Product
  | Role
  | Sale
  | Settings
  | Step
  | Task
  | Template
  | TimeTracking
  | User
  | Whitelist
  | Workflow;

export type FlagUpdateInput = {
  kind: FlagKind;
};

export type GridData = OnboardingData;

export type GridDataInput = {
  onboardingTypes?: InputMaybe<Array<OnboardingTypes>>;
  type: DataType;
};

export type GridFilter =
  | CreateEventsGraphFilter
  | DealsFilter
  | OnboardingFilter
  | RealtimeActivityFilter
  | SalePipelineFilter
  | SalesGraphFilter;

export type GridFilterInput = {
  activityTypes?: InputMaybe<Array<ACTIVITY_TYPE>>;
  creator?: InputMaybe<Array<Scalars["ObjectId"]>>;
  date?: InputMaybe<DateRange>;
  onboardingTypes?: InputMaybe<Array<OnboardingTypes>>;
  period?: InputMaybe<Period>;
  probability?: InputMaybe<Scalars["Float"]>;
  type: FilterType;
  user?: InputMaybe<Scalars["ObjectId"]>;
};

export type GridInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  data?: InputMaybe<GridDataInput>;
  element: Scalars["String"];
  filter?: InputMaybe<GridFilterInput>;
  name?: InputMaybe<Scalars["String"]>;
  position: PositionInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GridItem = {
  __typename: "GridItem";
  _id: Scalars["ObjectId"];
  data: Maybe<GridData>;
  element: Scalars["String"];
  filter: Maybe<GridFilter>;
  name: Maybe<Scalars["String"]>;
  position: ItemPosition;
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export type ImportDataInput = {
  customers?: InputMaybe<Array<CustomerImportInput>>;
  notes?: InputMaybe<Array<CreateActivityInput>>;
  people?: InputMaybe<Array<PersonInput>>;
};

export type ImportDataModelResult = {
  __typename: "ImportDataModelResult";
  acceptedCount: Scalars["Float"];
  data: Scalars["JSON"];
  errorsCount: Scalars["Float"];
  importId: Scalars["ObjectId"];
};

export type ImportDataResult = {
  __typename: "ImportDataResult";
  customers: Maybe<ImportDataModelResult>;
  notes: Maybe<ImportDataModelResult>;
  people: Maybe<ImportDataModelResult>;
};

export type ImportHistoryType = {
  __typename: "ImportHistoryType";
  _id: Scalars["ObjectId"];
  createdAt: Maybe<Scalars["DateTime"]>;
  performedBy: Maybe<User>;
};

export type IndustryType = {
  __typename: "IndustryType";
  _id: Scalars["ObjectId"];
  name: Scalars["String"];
};

export enum IntegrationKind {
  CALENDAR = "CALENDAR",
}

export enum IntegrationStatus {
  ACTIVE = "ACTIVE",
  CONNECTING = "CONNECTING",
  FAILED = "FAILED",
  INITIALIZED = "INITIALIZED",
}

export enum Interval {
  d = "d",
  day = "day",
  days = "days",
  h = "h",
  hour = "hour",
  hours = "hours",
  m = "m",
  month = "month",
  months = "months",
  w = "w",
  week = "week",
  weeks = "weeks",
  y = "y",
  year = "year",
  years = "years",
}

export type Introspection = {
  __typename: "Introspection";
  auth: authIntrospection;
  billing: billingIntrospection;
  conversation: conversationIntrospection;
  id: Scalars["String"];
  main: mainIntrospection;
  oidc: oidcIntrospection;
};

export type IntrospectionPayload = {
  __typename: "IntrospectionPayload";
  abilities: Maybe<Array<AbilityIntrospectionPayload>>;
  allowedClients: Maybe<Array<Scalars["String"]>>;
  moduleRestrictions: Maybe<Array<Scalars["String"]>>;
};

export type InvitationMetadata = {
  __typename: "InvitationMetadata";
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  expireAfterFirstUse: Scalars["Boolean"];
  key: Scalars["String"];
  metadata: InvitationMetadataPayload;
  ttl: Maybe<Scalars["Float"]>;
};

export type InvitationMetadataPayload = {
  __typename: "InvitationMetadataPayload";
  member: InvitationMetadataPayloadMember;
};

export type InvitationMetadataPayloadMember = {
  __typename: "InvitationMetadataPayloadMember";
  email: Scalars["String"];
  name: Scalars["String"];
  phone: Maybe<Scalars["String"]>;
};

export type InviteUserInput = {
  language?: InputMaybe<Language>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone: Scalars["String"];
  position?: InputMaybe<UserPosition>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type Invoice = {
  __typename: "Invoice";
  _id: Scalars["ObjectId"];
  invoiceNum: Scalars["Float"];
  period: Maybe<DateRangeType>;
  products: Array<InvoiceProduct>;
  subtotal: Scalars["Float"];
  type: InvoiceType;
};

export type InvoiceProduct = {
  __typename: "InvoiceProduct";
  linePrice: Scalars["Float"];
  name: Scalars["String"];
  price: Scalars["Float"];
  product: Scalars["ObjectId"];
  qty: Scalars["Float"];
  sku: Maybe<Scalars["String"]>;
};

export enum InvoiceType {
  recurring = "recurring",
  single = "single",
}

export type Item = {
  __typename: "Item";
  id: Scalars["String"];
  price: Price;
  quantity: Scalars["Float"];
};

export type ItemPosition = {
  __typename: "ItemPosition";
  _id: Maybe<Scalars["ObjectId"]>;
  h: Scalars["Float"];
  w: Scalars["Float"];
  x: Scalars["Float"];
  y: Scalars["Float"];
};

export type JobLevel = {
  __typename: "JobLevel";
  key: Scalars["String"];
  value: Scalars["String"];
};

export enum Language {
  en = "en",
  nb = "nb",
}

export type List = {
  __typename: "List";
  _id: Scalars["String"];
  createdAt: Maybe<Scalars["DateTime"]>;
  createdBy: Maybe<User>;
  description: Maybe<Scalars["String"]>;
  domain: EntityName;
  dynamic: Scalars["Boolean"];
  emoji: Maybe<Scalars["String"]>;
  filter: ListFilterArgs;
  lists: RelatedListFilterArgs;
  name: Scalars["String"];
  share: SharingType;
  updatedAt: Maybe<Scalars["DateTime"]>;
  updatedBy: Maybe<User>;
};

export type ListFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  domain?: InputMaybe<EntityName>;
  dynamic?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ListFilterArgs = {
  __typename: "ListFilterArgs";
  and: Maybe<Array<ListFilterUnitArgs>>;
  not: Maybe<Array<ListFilterUnitArgs>>;
  or: Maybe<Array<ListFilterUnitArgs>>;
};

export type ListFilterInput = {
  and?: InputMaybe<Array<ListFilterUnitInput>>;
  not?: InputMaybe<Array<ListFilterUnitInput>>;
  or?: InputMaybe<Array<ListFilterUnitInput>>;
};

export type ListFilterUnitArgs = {
  __typename: "ListFilterUnitArgs";
  metadata: Maybe<ListFilterUnitMetadataArgs>;
  operator: FilterUnitOperator;
  path: Maybe<Scalars["String"]>;
  resolve: Maybe<ListFilterUnitResolveArgs>;
  value: Maybe<Scalars["JSON"]>;
};

export type ListFilterUnitInput = {
  operator: FilterUnitOperator;
  path?: InputMaybe<Scalars["String"]>;
  resolve?: InputMaybe<ListFilterUnitResolveInput>;
  value?: InputMaybe<Scalars["JSON"]>;
};

export type ListFilterUnitMetadataArgs = {
  __typename: "ListFilterUnitMetadataArgs";
  invalid: Scalars["Boolean"];
  value: Maybe<Scalars["JSONObject"]>;
};

export type ListFilterUnitResolveArgs = {
  __typename: "ListFilterUnitResolveArgs";
  domain: EntityName;
  pick: Array<Scalars["String"]>;
};

export type ListFilterUnitResolveInput = {
  domain: EntityName;
  pick: Array<Scalars["String"]>;
};

export type ListInput = {
  description?: InputMaybe<Scalars["String"]>;
  dynamic: Scalars["Boolean"];
  emoji?: InputMaybe<Scalars["String"]>;
  filter: ListFilterInput;
  lists?: InputMaybe<RelatedListFilterArgsInput>;
  name: Scalars["String"];
};

export enum MAILER_INTEGRATION_STATUS {
  AUTHENTICATIONERROR = "AUTHENTICATIONERROR",
  CONNECTED = "CONNECTED",
  CONNECTERROR = "CONNECTERROR",
  CONNECTING = "CONNECTING",
  DISCONNECTED = "DISCONNECTED",
  INIT = "INIT",
  SYNCING = "SYNCING",
  UNSET = "UNSET",
}

export type MailerAccount = {
  __typename: "MailerAccount";
  _id: Scalars["ObjectId"];
  email: Scalars["String"];
  provider: MailerCommunicationProvider;
  reason: Maybe<Scalars["String"]>;
  status: MAILER_INTEGRATION_STATUS;
};

export enum MailerCommunicationProvider {
  GMAIL = "GMAIL",
  GMAILSERVICE = "GMAILSERVICE",
  IMAP = "IMAP",
  MAILRU = "MAILRU",
  OAUTH2 = "OAUTH2",
  OUTLOOK = "OUTLOOK",
}

export type MailerNotification = Notification & {
  __typename: "MailerNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  deActivatedAt: Scalars["DateTime"];
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  retries: Scalars["Float"];
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  status: MAILER_INTEGRATION_STATUS;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type ManageSubscriptionInput = {
  organizationId: Scalars["ObjectId"];
  returnUrl: Scalars["String"];
};

export type ManageSubscriptionPortal = {
  __typename: "ManageSubscriptionPortal";
  sessionId: Scalars["String"];
  sessionUrl: Scalars["String"];
};

export type MemberInput = {
  email: Scalars["String"];
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  role: Scalars["ObjectId"];
};

export type MentionNotification = Notification & {
  __typename: "MentionNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  customer: Customer;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  order: Maybe<Order>;
  sale: Maybe<Sale>;
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type MessageActivity = Activity & {
  __typename: "MessageActivity";
  _id: Scalars["ObjectId"];
  conversation: Maybe<Conversation>;
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  from: Maybe<ConversationRecipient>;
  message: Maybe<ConversationMessage>;
  preview: Maybe<Scalars["String"]>;
  subject: Maybe<Scalars["String"]>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type Meta = {
  __typename: "Meta";
  fields: Maybe<Scalars["JSON"]>;
};

export type MetaInput = {
  fields?: InputMaybe<Scalars["JSON"]>;
};

export enum Modules {
  BackOffice = "BackOffice",
  Beta = "Beta",
  Billing = "Billing",
  CalendarIntegration = "CalendarIntegration",
  CustomerRelations = "CustomerRelations",
  Order = "Order",
  TimeTracking = "TimeTracking",
}

export type Mutation = {
  __typename: "Mutation";
  acceptDocument: Scalars["Boolean"];
  activateMailerAccount: Scalars["String"];
  addEmailAddressToVerify: VerifiedEmailAddress;
  addFlag: Flag;
  addStep: Step;
  authenticatedChangePassword: Scalars["Boolean"];
  completeCalendarIntegration: CalendarIntegration;
  completeSaleDraft: Sale;
  createActivity: Activity;
  createAdvancedFilter: AdvancedFilter;
  createContract: Contract;
  createCustomField: CustomField;
  createCustomer: Maybe<Customer>;
  createCustomerCategory: CustomerCategory;
  createCustomerStatus: CustomerStatus;
  createDashboard: Dashboard;
  createDeliveryAddress: DeliveryAddressType;
  createEvent: Event;
  createEventType: EventType;
  createList: List;
  createOidcClient: OidcClient;
  createOrResumeCheckout: Checkout;
  createOrder: Order;
  createOrderTask: OrderTask;
  createPerson: Person;
  createProduct: Product;
  createProviderEvent: Event;
  createRelation: Scalars["Boolean"];
  createRole: Role;
  createSale: Sale;
  createTask: Task;
  createTenant: Scalars["Boolean"];
  createTimeTracking: TimeTracking;
  createWhitelistRecord: Whitelist;
  createWorkflow: Workflow;
  dangerouslyHardDeleteOrganization: Organization;
  dangerouslyResetOrganization: Organization;
  deleteAdvancedFilter: AdvancedFilter;
  deleteContract: Contract;
  deleteConversation: Conversation;
  deleteConversationMessage: ConversationMessage;
  deleteCustomField: CustomField;
  deleteCustomer: Customer;
  deleteCustomerCategory: CustomerCategory;
  deleteCustomerStatus: Array<CustomerStatus>;
  deleteDashboard: Dashboard;
  deleteEvent: Event;
  deleteEventType: Array<EventType>;
  deleteFile: File;
  deleteList: List;
  deleteMailerAccount: Scalars["Boolean"];
  deleteManyCustomers: Array<Customer>;
  deleteManySales: Array<Sale>;
  deleteManyTasks: Array<Scalars["ObjectId"]>;
  deleteManyTimeTrackings: Array<TimeTracking>;
  deleteOrder: Order;
  deleteOrderTask: OrderTask;
  deleteOrders: Array<Order>;
  deletePeople: Array<Person>;
  deletePerson: Person;
  deleteProduct: Product;
  deleteProviderEvent: Event;
  deleteRole: Role;
  deleteSale: Sale;
  deleteSettings: SettingsUnion;
  deleteStep: Step;
  deleteTask: Task;
  deleteTimeTracking: TimeTracking;
  deleteVerifiedEmailAddress: VerifiedEmailAddress;
  deleteWorkflow: Workflow;
  disableCalendarIntegration: CalendarIntegration;
  enableCalendarIntegration: ActivationResponse;
  exportManyCustomers: Exportable;
  exportManyOrders: Exportable;
  exportManyPeople: Exportable;
  exportManySales: Exportable;
  exportManyTasks: Exportable;
  exportManyTimeTrackings: Exportable;
  fileUrl: Scalars["String"];
  finishCheckout: CheckoutSession;
  importData: ImportDataResult;
  inviteExistingUserCallback: User;
  inviteNewUserCallback: User;
  inviteUser: Scalars["Boolean"];
  lockOrganization: Organization;
  login: AuthenticationResponse;
  makeListPrivate: List;
  makeListProtected: List;
  makeListPublic: List;
  manageSubscription: ManageSubscriptionPortal;
  manuallyApplyOnboarding: Scalars["Boolean"];
  manuallySignSale: Sale;
  markNotification: Scalars["Boolean"];
  markVerifiedEmailAddressAsDefault: VerifiedEmailAddress;
  moveCustomField: CustomField;
  moveOrderTask: OrderTask;
  moveProduct: Product;
  moveTask: Task;
  publicChangePassword: Scalars["Boolean"];
  publicChangePasswordCallback: User;
  registerOrganization: Organization;
  registerUser: Scalars["Boolean"];
  registerUserCallback: Scalars["String"];
  registerUserGetMetadata: RegistrationMetadata;
  removeCustomerBillingAddress: Billing;
  removeDeliveryAddress: DeliveryAddressType;
  removeFlag: Flag;
  removeOidcClient: OidcClient;
  removeOrganizationLogo: Scalars["Boolean"];
  removeRelation: Scalars["Boolean"];
  removeWhitelistRecord: Scalars["Boolean"];
  requestSignature: Scalars["Boolean"];
  revertImport: RevertImportResult;
  revokeAll: Scalars["Boolean"];
  revokeMember: Scalars["Boolean"];
  revokeOidcAuthorization: Scalars["Boolean"];
  saveOnboarding: Organization;
  setCustomFieldValue: Scalars["JSON"];
  setCustomFieldValues: Scalars["JSON"];
  setCustomerCategories: Customer;
  setCustomerStatus: Customer;
  shareList: Array<Sharing>;
  shareListByEmail: Array<Sharing>;
  toggleAbilities: Role;
  toggleAccessToModule: Organization;
  toggleDisableCustomerCategory: CustomerCategory;
  toggleListRecord: List;
  togglePersonFavorite: Person;
  unlockOrganization: Organization;
  unshareList: Array<Sharing>;
  unshareListByEmail: Array<Sharing>;
  updateAdvancedFilter: AdvancedFilter;
  updateCheckout: Checkout;
  updateConfirmationTemplate: Template;
  updateContract: Contract;
  updateCustomField: CustomField;
  updateCustomer: Maybe<Customer>;
  updateCustomerAssigned: Maybe<Customer>;
  updateCustomerBillingAddress: Billing;
  updateCustomerCategories: Array<CustomerCategory>;
  updateCustomerCategory: CustomerCategory;
  updateCustomerStatuses: Array<CustomerStatus>;
  updateDashboard: Dashboard;
  updateDeliveryAddress: DeliveryAddressType;
  updateEvent: Event;
  updateEventTypes: Array<EventType>;
  updateFlag: Flag;
  updateGridItem: GridItem;
  updateList: List;
  updateMaxMembers: Organization;
  updateOidcClient: OidcClient;
  updateOrder: Order;
  updateOrderBillingAddress: Billing;
  updateOrderStatus: Order;
  updateOrderTask: OrderTask;
  updateOrderTasks: Array<OrderTask>;
  updateOrganization: Organization;
  updatePerson: Person;
  updateProduct: Product;
  updateProviderEvent: Event;
  updateRole: Role;
  updateSale: Sale;
  updateSaleStatus: Sale;
  updateSignatureRequestTemplate: Template;
  updateStep: Step;
  updateSubscription: OrganizationBilling;
  updateTask: Task;
  updateTimeTracking: TimeTracking;
  updateUser: User;
  updateUserSignature: Template;
  updateWhitelistRecord: Scalars["Boolean"];
  updateWorkflow: Workflow;
  upsertCalendarSettings: CalendarSettings;
  upsertNotificationSettings: NotificationSettings;
  upsertSaleDraft: Sale;
  upsertSettings: Settings;
  upsertTaskSettings: TaskSettings;
};

export type MutationacceptDocumentArgs = {
  token: Scalars["ObjectId"];
};

export type MutationaddEmailAddressToVerifyArgs = {
  input: AddEmailAddressToVerifyInput;
};

export type MutationaddFlagArgs = {
  input: FlagCreateInput;
};

export type MutationaddStepArgs = {
  addAfter?: InputMaybe<Scalars["ObjectId"]>;
  addBefore?: InputMaybe<Scalars["ObjectId"]>;
  step: StepInput;
  workflowId: Scalars["ObjectId"];
};

export type MutationauthenticatedChangePasswordArgs = {
  oldPassword: Scalars["String"];
  password: Scalars["String"];
};

export type MutationcompleteCalendarIntegrationArgs = {
  account: Scalars["ObjectId"];
  code: Scalars["String"];
};

export type MutationcompleteSaleDraftArgs = {
  id: Scalars["ObjectId"];
  input?: InputMaybe<SaleDraftInput>;
};

export type MutationcreateActivityArgs = {
  input?: InputMaybe<CreateActivityInput>;
};

export type MutationcreateAdvancedFilterArgs = {
  input: AdvancedFilterInput;
};

export type MutationcreateContractArgs = {
  input: ContractInput;
};

export type MutationcreateCustomFieldArgs = {
  domain: EntityName;
  input: CustomFieldInput;
};

export type MutationcreateCustomerArgs = {
  ignoreDuplicate?: InputMaybe<Scalars["Boolean"]>;
  input: CustomerInput;
};

export type MutationcreateCustomerCategoryArgs = {
  input: CustomerCategoryInput;
};

export type MutationcreateCustomerStatusArgs = {
  input: CreateCustomerStatus;
};

export type MutationcreateDashboardArgs = {
  input: CreateDashboardInput;
};

export type MutationcreateDeliveryAddressArgs = {
  address: DeliveryAddressInput;
  customerId: Scalars["ObjectId"];
  updateExistingInOrders?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationcreateEventArgs = {
  input: CreateEventInput;
};

export type MutationcreateEventTypeArgs = {
  input: CreateEventType;
};

export type MutationcreateListArgs = {
  domain: EntityName;
  input: ListInput;
};

export type MutationcreateOidcClientArgs = {
  input: OidcClientInput;
};

export type MutationcreateOrResumeCheckoutArgs = {
  input: CheckoutInput;
  organization: Scalars["ObjectId"];
};

export type MutationcreateOrderArgs = {
  saleId: Scalars["ObjectId"];
};

export type MutationcreateOrderTaskArgs = {
  input: OrderTaskInput;
};

export type MutationcreatePersonArgs = {
  input: PersonInput;
};

export type MutationcreateProductArgs = {
  input: CreateProductInput;
};

export type MutationcreateProviderEventArgs = {
  account: Scalars["ObjectId"];
  calendar: Scalars["String"];
  input: CreateEventInput;
};

export type MutationcreateRelationArgs = {
  relation: RelationType;
  source: Scalars["ObjectId"];
  target: Scalars["ObjectId"];
};

export type MutationcreateRoleArgs = {
  input: NewRoleInput;
};

export type MutationcreateSaleArgs = {
  input: SaleInput;
};

export type MutationcreateTaskArgs = {
  input: TaskInput;
  step?: InputMaybe<Scalars["ObjectId"]>;
  timeTrackings?: InputMaybe<Array<RawTimeTrackingInput>>;
  workflow?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationcreateTenantArgs = {
  input: CreateTenantInput;
};

export type MutationcreateTimeTrackingArgs = {
  input: TimeTrackingInput;
};

export type MutationcreateWhitelistRecordArgs = {
  input: WhitelistInput;
};

export type MutationcreateWorkflowArgs = {
  input: WorkflowInput;
  steps?: InputMaybe<Array<StepInput>>;
};

export type MutationdangerouslyHardDeleteOrganizationArgs = {
  organizationId: Scalars["ObjectId"];
};

export type MutationdangerouslyResetOrganizationArgs = {
  organizationId: Scalars["ObjectId"];
};

export type MutationdeleteAdvancedFilterArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteContractArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteConversationArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteConversationMessageArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteCustomFieldArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteCustomerArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteCustomerCategoryArgs = {
  categoryId: Scalars["ObjectId"];
  migrateTo?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationdeleteCustomerStatusArgs = {
  migrateTo?: InputMaybe<Scalars["ObjectId"]>;
  status: Scalars["ObjectId"];
};

export type MutationdeleteDashboardArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteEventArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteEventTypeArgs = {
  eventTypeId: Scalars["ObjectId"];
  migrateTo: Scalars["ObjectId"];
};

export type MutationdeleteFileArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteListArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteMailerAccountArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteManyCustomersArgs = {
  _ids: Array<Scalars["ObjectId"]>;
};

export type MutationdeleteManySalesArgs = {
  _ids: Array<Scalars["ObjectId"]>;
};

export type MutationdeleteManyTasksArgs = {
  deleteAll?: Scalars["Boolean"];
  exclude?: Array<Scalars["ObjectId"]>;
  include?: Array<Scalars["ObjectId"]>;
};

export type MutationdeleteManyTimeTrackingsArgs = {
  _ids: Array<Scalars["ObjectId"]>;
};

export type MutationdeleteOrderArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteOrderTaskArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteOrdersArgs = {
  _ids: Array<Scalars["ObjectId"]>;
};

export type MutationdeletePeopleArgs = {
  _ids: Array<Scalars["ObjectId"]>;
};

export type MutationdeletePersonArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteProductArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteProviderEventArgs = {
  account: Scalars["ObjectId"];
  calendar: Scalars["String"];
  id: Scalars["String"];
};

export type MutationdeleteRoleArgs = {
  migrateTo?: InputMaybe<Scalars["ObjectId"]>;
  roleId: Scalars["ObjectId"];
};

export type MutationdeleteSaleArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteSettingsArgs = {
  area?: InputMaybe<EntityName>;
  domain?: InputMaybe<EntityName>;
  global?: InputMaybe<Scalars["Boolean"]>;
  resource?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationdeleteStepArgs = {
  fallbackId?: InputMaybe<Scalars["ObjectId"]>;
  stepId: Scalars["ObjectId"];
  workflowId: Scalars["ObjectId"];
};

export type MutationdeleteTaskArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteTimeTrackingArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationdeleteVerifiedEmailAddressArgs = {
  emailAddressId: Scalars["ObjectId"];
};

export type MutationdeleteWorkflowArgs = {
  fallbackWorkflowMap: FallbackWorkflowMapInput;
};

export type MutationdisableCalendarIntegrationArgs = {
  id: Scalars["ObjectId"];
};

export type MutationenableCalendarIntegrationArgs = {
  input: ActivationRequestInput;
};

export type MutationexportManyCustomersArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<CustomerFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type MutationexportManyOrdersArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<OrderFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type MutationexportManyPeopleArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  filter?: InputMaybe<PersonFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type MutationexportManySalesArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<SaleFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type MutationexportManyTasksArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<TaskFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type MutationexportManyTimeTrackingsArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<TimeTrackingFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type MutationfileUrlArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationfinishCheckoutArgs = {
  organization: Scalars["ObjectId"];
};

export type MutationimportDataArgs = {
  input: ImportDataInput;
};

export type MutationinviteExistingUserCallbackArgs = {
  code: Scalars["String"];
};

export type MutationinviteNewUserCallbackArgs = {
  code: Scalars["String"];
  user: InviteUserInput;
};

export type MutationinviteUserArgs = {
  member: MemberInput;
};

export type MutationlockOrganizationArgs = {
  organizationId: Scalars["ObjectId"];
};

export type MutationloginArgs = {
  client: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationmakeListPrivateArgs = {
  id: Scalars["ObjectId"];
};

export type MutationmakeListProtectedArgs = {
  id: Scalars["ObjectId"];
};

export type MutationmakeListPublicArgs = {
  id: Scalars["ObjectId"];
};

export type MutationmanageSubscriptionArgs = {
  input: ManageSubscriptionInput;
};

export type MutationmanuallyApplyOnboardingArgs = {
  organization: Scalars["ObjectId"];
};

export type MutationmanuallySignSaleArgs = {
  _id: Scalars["ObjectId"];
  sendConfirmationTo?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationmarkNotificationArgs = {
  markAll?: InputMaybe<Scalars["Boolean"]>;
  markAs: Scalars["Boolean"];
  markSelected?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationmarkVerifiedEmailAddressAsDefaultArgs = {
  emailAddressId: Scalars["ObjectId"];
};

export type MutationmoveCustomFieldArgs = {
  after?: InputMaybe<Scalars["ObjectId"]>;
  before?: InputMaybe<Scalars["ObjectId"]>;
  domain: EntityName;
  id: Scalars["ObjectId"];
};

export type MutationmoveOrderTaskArgs = {
  after?: InputMaybe<Scalars["ObjectId"]>;
  before?: InputMaybe<Scalars["ObjectId"]>;
  id: Scalars["ObjectId"];
};

export type MutationmoveProductArgs = {
  after?: InputMaybe<Scalars["ObjectId"]>;
  before?: InputMaybe<Scalars["ObjectId"]>;
  id: Scalars["ObjectId"];
};

export type MutationmoveTaskArgs = {
  _id: Scalars["ObjectId"];
  after?: InputMaybe<Scalars["ObjectId"]>;
  before?: InputMaybe<Scalars["ObjectId"]>;
  step?: InputMaybe<Scalars["ObjectId"]>;
  workflow?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationpublicChangePasswordArgs = {
  email: Scalars["String"];
};

export type MutationpublicChangePasswordCallbackArgs = {
  code: Scalars["String"];
  password: Scalars["String"];
};

export type MutationregisterOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationregisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationregisterUserCallbackArgs = {
  code: Scalars["String"];
};

export type MutationregisterUserGetMetadataArgs = {
  code: Scalars["String"];
};

export type MutationremoveCustomerBillingAddressArgs = {
  customerId: Scalars["ObjectId"];
};

export type MutationremoveDeliveryAddressArgs = {
  addressId: Scalars["ObjectId"];
  customerId: Scalars["ObjectId"];
};

export type MutationremoveFlagArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationremoveOidcClientArgs = {
  _id: Scalars["String"];
};

export type MutationremoveRelationArgs = {
  relation: RelationType;
  source: Scalars["ObjectId"];
  target: Scalars["ObjectId"];
};

export type MutationremoveWhitelistRecordArgs = {
  recordId: Scalars["ObjectId"];
};

export type MutationrequestSignatureArgs = {
  contract: Scalars["ObjectId"];
  saleId: Scalars["ObjectId"];
  signee: Scalars["ObjectId"];
};

export type MutationrevertImportArgs = {
  importId: Scalars["ObjectId"];
};

export type MutationrevokeAllArgs = {
  resource: Scalars["ObjectId"];
};

export type MutationrevokeMemberArgs = {
  member: Scalars["ObjectId"];
};

export type MutationrevokeOidcAuthorizationArgs = {
  clientId: Scalars["String"];
};

export type MutationsaveOnboardingArgs = {
  input: OrganizationInput;
};

export type MutationsetCustomFieldValueArgs = {
  domain: EntityName;
  field: Scalars["ObjectId"];
  resource: Scalars["ObjectId"];
  value: Scalars["JSON"];
};

export type MutationsetCustomFieldValuesArgs = {
  domain: EntityName;
  resource: Scalars["ObjectId"];
  values: Scalars["JSON"];
};

export type MutationsetCustomerCategoriesArgs = {
  categories: Array<Scalars["ObjectId"]>;
  customerId: Scalars["ObjectId"];
};

export type MutationsetCustomerStatusArgs = {
  customerId: Scalars["ObjectId"];
  statusId: Scalars["ObjectId"];
};

export type MutationshareListArgs = {
  id: Scalars["ObjectId"];
  users: Array<Scalars["ObjectId"]>;
};

export type MutationshareListByEmailArgs = {
  id: Scalars["ObjectId"];
  users: Array<Scalars["String"]>;
};

export type MutationtoggleAbilitiesArgs = {
  abilities: Array<AbilityInput>;
  roleId: Scalars["ObjectId"];
};

export type MutationtoggleAccessToModuleArgs = {
  module: Scalars["String"];
};

export type MutationtoggleDisableCustomerCategoryArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationtoggleListRecordArgs = {
  _id: Scalars["ObjectId"];
  record: Array<Scalars["ObjectId"]>;
};

export type MutationtogglePersonFavoriteArgs = {
  _id: Scalars["ObjectId"];
};

export type MutationunlockOrganizationArgs = {
  organizationId: Scalars["ObjectId"];
};

export type MutationunshareListArgs = {
  id: Scalars["ObjectId"];
  users: Array<Scalars["ObjectId"]>;
};

export type MutationunshareListByEmailArgs = {
  id: Scalars["ObjectId"];
  users: Array<Scalars["String"]>;
};

export type MutationupdateAdvancedFilterArgs = {
  _id: Scalars["ObjectId"];
  input: AdvancedFilterInput;
};

export type MutationupdateCheckoutArgs = {
  input: CheckoutInput;
  organization: Scalars["ObjectId"];
};

export type MutationupdateConfirmationTemplateArgs = {
  input: Scalars["JSON"];
};

export type MutationupdateContractArgs = {
  _id: Scalars["ObjectId"];
  input: ContractInput;
};

export type MutationupdateCustomFieldArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateCustomFieldInput;
  valuesToMigrate?: Array<CustomFieldValueToMigrateInput>;
};

export type MutationupdateCustomerArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateCustomerInput;
};

export type MutationupdateCustomerAssignedArgs = {
  _id: Scalars["ObjectId"];
  assigned: Array<Scalars["ObjectId"]>;
};

export type MutationupdateCustomerBillingAddressArgs = {
  customerId: Scalars["ObjectId"];
  input?: InputMaybe<CustomerBillingAddressInput>;
  updateExistingInOrders?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationupdateCustomerCategoriesArgs = {
  input: Array<CustomerCategoryInput>;
};

export type MutationupdateCustomerCategoryArgs = {
  input: CustomerCategoryInput;
};

export type MutationupdateCustomerStatusesArgs = {
  input: CustomerStatusesInput;
};

export type MutationupdateDashboardArgs = {
  _id: Scalars["ObjectId"];
  input: CreateDashboardInput;
};

export type MutationupdateDeliveryAddressArgs = {
  address: DeliveryAddressInput;
  customerId: Scalars["ObjectId"];
  updateExistingInOrders?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationupdateEventArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateEventInput;
};

export type MutationupdateEventTypesArgs = {
  input: EventTypesInput;
};

export type MutationupdateFlagArgs = {
  _id: Scalars["ObjectId"];
  input: FlagUpdateInput;
};

export type MutationupdateGridItemArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateGridInput;
};

export type MutationupdateListArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateListInput;
};

export type MutationupdateMaxMembersArgs = {
  maxMembers: Scalars["Float"];
  organizationId: Scalars["ObjectId"];
};

export type MutationupdateOidcClientArgs = {
  _id: Scalars["String"];
  input: OidcClientInput;
};

export type MutationupdateOrderArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateOrderInput;
};

export type MutationupdateOrderBillingAddressArgs = {
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  input: OrderBillingAddressInput;
  orderId: Scalars["ObjectId"];
};

export type MutationupdateOrderStatusArgs = {
  _id: Scalars["ObjectId"];
  status: OrderStatus;
};

export type MutationupdateOrderTaskArgs = {
  _id: Scalars["ObjectId"];
  input: OrderTaskInput;
};

export type MutationupdateOrderTasksArgs = {
  tasks: Array<OrderTaskInput>;
};

export type MutationupdateOrganizationArgs = {
  input: OrganizationInput;
};

export type MutationupdatePersonArgs = {
  _id: Scalars["ObjectId"];
  input: PersonInput;
};

export type MutationupdateProductArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateProductInput;
};

export type MutationupdateProviderEventArgs = {
  account: Scalars["ObjectId"];
  calendar: Scalars["String"];
  id: Scalars["String"];
  input: UpdateEventInput;
};

export type MutationupdateRoleArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateRoleInput;
};

export type MutationupdateSaleArgs = {
  _id: Scalars["ObjectId"];
  input: SaleInput;
};

export type MutationupdateSaleStatusArgs = {
  _id: Scalars["ObjectId"];
  expectedAt?: InputMaybe<Scalars["DateTime"]>;
  probability?: InputMaybe<Scalars["Int"]>;
  status: SaleStatus;
};

export type MutationupdateSignatureRequestTemplateArgs = {
  input: Scalars["JSON"];
};

export type MutationupdateStepArgs = {
  moveAfter?: InputMaybe<Scalars["ObjectId"]>;
  moveBefore?: InputMaybe<Scalars["ObjectId"]>;
  step: StepInput;
  stepId: Scalars["ObjectId"];
  workflowId: Scalars["ObjectId"];
};

export type MutationupdateSubscriptionArgs = {
  id: Scalars["ObjectId"];
  input: UpdateSubscriptionInput;
};

export type MutationupdateTaskArgs = {
  _id: Scalars["ObjectId"];
  after?: InputMaybe<Scalars["ObjectId"]>;
  before?: InputMaybe<Scalars["ObjectId"]>;
  input: TaskInput;
  step?: InputMaybe<Scalars["ObjectId"]>;
  timeTrackings?: InputMaybe<Array<RawTimeTrackingInput>>;
  workflow?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationupdateTimeTrackingArgs = {
  _id: Scalars["ObjectId"];
  input: TimeTrackingInput;
};

export type MutationupdateUserArgs = {
  input: UpdateUser;
};

export type MutationupdateUserSignatureArgs = {
  content: Scalars["JSON"];
};

export type MutationupdateWhitelistRecordArgs = {
  input: WhitelistInput;
  recordId: Scalars["ObjectId"];
};

export type MutationupdateWorkflowArgs = {
  _id: Scalars["ObjectId"];
  input: UpdateWorkflowInput;
};

export type MutationupsertCalendarSettingsArgs = {
  input: CalendarSettingsPayloadInput;
};

export type MutationupsertNotificationSettingsArgs = {
  input: NotificationSettingsPayloadInput;
};

export type MutationupsertSaleDraftArgs = {
  id?: InputMaybe<Scalars["ObjectId"]>;
  input: SaleDraftInput;
};

export type MutationupsertSettingsArgs = {
  area?: InputMaybe<EntityName>;
  domain?: InputMaybe<EntityName>;
  global?: InputMaybe<Scalars["Boolean"]>;
  payload: Scalars["JSON"];
  resource?: InputMaybe<Scalars["ObjectId"]>;
};

export type MutationupsertTaskSettingsArgs = {
  area?: InputMaybe<EntityName>;
  domain: EntityName;
  payload: TaskSettingsPayloadInput;
  resource?: InputMaybe<Scalars["ObjectId"]>;
};

export enum NOTIFICATION_TYPE {
  EVENT_REMINDER = "EVENT_REMINDER",
  EXPIRING_CONTRACT = "EXPIRING_CONTRACT",
  EXPORT_COMPLETE = "EXPORT_COMPLETE",
  EXPORT_EMPTY = "EXPORT_EMPTY",
  EXPORT_ERROR = "EXPORT_ERROR",
  LOGIN_REQUESTED = "LOGIN_REQUESTED",
  MAILER_FAILURE = "MAILER_FAILURE",
  MENTIONED_IN_NOTE = "MENTIONED_IN_NOTE",
  RESOURCE_SHARED = "RESOURCE_SHARED",
  TASK_ASSIGNED = "TASK_ASSIGNED",
  TASK_ASSIGNED_EXPIRING = "TASK_ASSIGNED_EXPIRING",
  WON_SALE = "WON_SALE",
}

export type NestedFilterArgs = {
  __typename: "NestedFilterArgs";
  and: Maybe<Array<FilterUnitArgs>>;
  not: Maybe<Array<FilterUnitArgs>>;
  or: Maybe<Array<FilterUnitArgs>>;
};

export type NestedFilterArgsInput = {
  and?: InputMaybe<Array<FilterUnitArgsInput>>;
  not?: InputMaybe<Array<FilterUnitArgsInput>>;
  or?: InputMaybe<Array<FilterUnitArgsInput>>;
};

export type NewRoleInput = {
  abilities?: InputMaybe<Array<AbilityInput>>;
  name: Scalars["String"];
  users: Array<Scalars["ObjectId"]>;
};

export type NoteActivity = Activity & {
  __typename: "NoteActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  mentioned: Maybe<User>;
  note: Scalars["String"];
  order: Maybe<Order>;
  sale: Maybe<Sale>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type Notification = {
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type NotificationChannel = {
  __typename: "NotificationChannel";
  app: Scalars["Boolean"];
  email: Scalars["Boolean"];
  when: Maybe<NotificationSettingsWhen>;
};

export type NotificationChannelInput = {
  app: Scalars["Boolean"];
  email: Scalars["Boolean"];
  when?: InputMaybe<NotificationSettingsWhenInput>;
};

export type NotificationFilter = {
  isRead?: InputMaybe<Scalars["Boolean"]>;
};

export type NotificationSettings = {
  __typename: "NotificationSettings";
  _id: Maybe<Scalars["ObjectId"]>;
  area: Maybe<EntityName>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  domain: Maybe<EntityName>;
  payload: NotificationSettingsPayload;
  resource: Maybe<Scalars["ObjectId"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<Scalars["ObjectId"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type NotificationSettingsPayload = {
  __typename: "NotificationSettingsPayload";
  eventReminder: NotificationChannel;
  expiringContract: NotificationChannel;
  mentionedInNote: NotificationChannel;
  taskAssigned: NotificationChannel;
  taskAssignedExpiring: NotificationChannel;
  wonSale: NotificationChannel;
};

export type NotificationSettingsPayloadInput = {
  eventReminder: NotificationChannelInput;
  expiringContract: NotificationChannelInput;
  mentionedInNote: NotificationChannelInput;
  taskAssigned: NotificationChannelInput;
  taskAssignedExpiring: NotificationChannelInput;
  wonSale: NotificationChannelInput;
};

export type NotificationSettingsWhen = {
  __typename: "NotificationSettingsWhen";
  unit: NotificationWhenUnit;
  value: Scalars["Float"];
};

export type NotificationSettingsWhenInput = {
  unit: NotificationWhenUnit;
  value: Scalars["Float"];
};

export enum NotificationWhenUnit {
  DAY = "DAY",
  HOUR = "HOUR",
  MINUTE = "MINUTE",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export type NumberRangeInput = {
  max?: InputMaybe<Scalars["Float"]>;
  min?: InputMaybe<Scalars["Float"]>;
};

export type OidcClient = {
  __typename: "OidcClient";
  _id: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy: User;
  payload: OidcClientPayload;
  user: User;
};

export type OidcClientInput = {
  client_name: Scalars["String"];
  client_uri?: InputMaybe<Scalars["String"]>;
  contacts?: InputMaybe<Array<Scalars["String"]>>;
  logo_uri?: InputMaybe<Scalars["String"]>;
  policy_uri?: InputMaybe<Scalars["String"]>;
  post_logout_redirect_uris?: InputMaybe<Array<Scalars["String"]>>;
  redirect_uris: Array<Scalars["String"]>;
  tos_uri?: InputMaybe<Scalars["String"]>;
};

export type OidcClientPayload = {
  __typename: "OidcClientPayload";
  client_id: Scalars["String"];
  client_name: Scalars["String"];
  client_secret: Scalars["String"];
  client_uri: Maybe<Scalars["String"]>;
  contacts: Maybe<Array<Scalars["String"]>>;
  logo_uri: Maybe<Scalars["String"]>;
  policy_uri: Maybe<Scalars["String"]>;
  redirect_uris: Maybe<Array<Scalars["String"]>>;
  tos_uri: Maybe<Scalars["String"]>;
};

export type OnboardingData = {
  __typename: "OnboardingData";
  onboardingTypes: Maybe<Array<OnboardingTypes>>;
};

export type OnboardingFilter = {
  __typename: "OnboardingFilter";
  onboardingTypes: Maybe<Array<OnboardingTypes>>;
};

export enum OnboardingRole {
  administrator = "administrator",
  sale = "sale",
  support = "support",
}

export enum OnboardingStep {
  closed = "closed",
  companyData = "companyData",
  dataProcessingAgreement = "dataProcessingAgreement",
  finishing = "finishing",
  industry = "industry",
  roles = "roles",
}

export enum OnboardingTypes {
  addFirstContactPerson = "addFirstContactPerson",
  addFirstTeammate = "addFirstTeammate",
  addYourFirstProduct = "addYourFirstProduct",
  changeYourPassword = "changeYourPassword",
  createYourFirstContract = "createYourFirstContract",
  findYourFirstCustomer = "findYourFirstCustomer",
}

export type Order = {
  __typename: "Order";
  _id: Scalars["ObjectId"];
  assignee: Maybe<User>;
  baseCurrency: Currency;
  basket: Array<BasketItem>;
  billing: Maybe<Billing>;
  billingAddress: Maybe<Billing>;
  billingFrequency: Maybe<Scalars["String"]>;
  cancelledAt: Maybe<Scalars["DateTime"]>;
  comment: Maybe<Scalars["String"]>;
  contract: Maybe<Contract>;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  currency: Currency;
  currencyDiscount: Scalars["Float"];
  currencySubtotal: Scalars["Float"];
  customSale: Maybe<Scalars["JSON"]>;
  customer: Customer;
  delayedAt: Maybe<Scalars["DateTime"]>;
  deliveryAddress: Maybe<DeliveryAddressType>;
  discount: Scalars["Float"];
  finishedAt: Maybe<Scalars["DateTime"]>;
  invoiceTotalPrice: Scalars["Float"];
  invoices: Array<Invoice>;
  isAddressDeliveryAddress: Scalars["Boolean"];
  isAutomaticallyCreated: Scalars["Boolean"];
  lists: Maybe<Array<List>>;
  meta: Maybe<Meta>;
  notStartedAt: Maybe<Scalars["DateTime"]>;
  onGoingAt: Maybe<Scalars["DateTime"]>;
  onHoldAt: Maybe<Scalars["DateTime"]>;
  orderContactPerson: Maybe<Person>;
  orderRef: Scalars["Int"];
  qty: Scalars["Float"];
  sale: Sale;
  saleContactPerson: Maybe<Person>;
  saleRef: Scalars["Int"];
  status: OrderStatus;
  subtotal: Scalars["Float"];
  totalNumInvoices: Scalars["Float"];
  tracking: Maybe<Tracking>;
  updatedAt: Scalars["DateTime"];
  userUpdatedAt: Scalars["DateTime"];
};

export type OrderActivity = Activity & {
  __typename: "OrderActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  currency: Currency;
  currencySubtotal: Scalars["Float"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  next: Maybe<OrderStatus>;
  order: Order;
  orderRef: Scalars["Int"];
  prev: Maybe<OrderStatus>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type OrderBillingAddressInput = {
  address?: InputMaybe<Scalars["String"]>;
  channel?: InputMaybe<BillingChannel>;
  channelReference?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  reference?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type OrderFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  createdAt?: InputMaybe<DateRange>;
  customer?: InputMaybe<Array<Scalars["ObjectId"]>>;
  stage?: InputMaybe<Array<Scalars["ObjectId"]>>;
  status?: InputMaybe<Array<OrderStatus>>;
  userUpdatedAt?: InputMaybe<DateRange>;
};

export type OrderSection = {
  __typename: "OrderSection";
  by: TASK_SECTIONING_OPTIONS;
  count: Scalars["Float"];
  sections: Array<OrderSectionMetadata>;
};

export type OrderSectionMetadata = {
  __typename: "OrderSectionMetadata";
  count: Scalars["Float"];
  payload: Order;
};

export enum OrderStatus {
  cancelled = "cancelled",
  delayed = "delayed",
  finished = "finished",
  notStarted = "notStarted",
  onGoing = "onGoing",
  onHold = "onHold",
}

export type OrderTask = {
  __typename: "OrderTask";
  _id: Scalars["ObjectId"];
  assigned: Maybe<Array<User>>;
  createdAt: Scalars["DateTime"];
  description: Maybe<Scalars["String"]>;
  dueIn: Maybe<Scalars["Float"]>;
  position: Maybe<Scalars["String"]>;
  step: Maybe<Step>;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type OrderTaskInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  description?: InputMaybe<Scalars["String"]>;
  dueIn?: InputMaybe<Scalars["Float"]>;
  step?: InputMaybe<Scalars["ObjectId"]>;
  title: Scalars["String"];
};

export type Organization = {
  __typename: "Organization";
  _id: Scalars["ObjectId"];
  address: Maybe<Scalars["String"]>;
  agreementAccepted: Maybe<Scalars["Boolean"]>;
  billing: Maybe<BillingAddressType>;
  billingDetails: Maybe<OrganizationBilling>;
  city: Scalars["String"];
  communication: CommunicationSettingsType;
  country: Scalars["String"];
  county: Maybe<Scalars["String"]>;
  createOrdersFromSales: Scalars["Boolean"];
  creator: Scalars["ObjectId"];
  customer: CustomerSettingsType;
  disabled: Scalars["Boolean"];
  hasLogo: Scalars["Boolean"];
  industry: Maybe<Scalars["String"]>;
  isLegacy: Scalars["Boolean"];
  isLocked: Scalars["Boolean"];
  logo: Maybe<Scalars["String"]>;
  maxMembers: Scalars["Float"];
  meta: Maybe<OrganizationMeta>;
  modules: Array<Modules>;
  municipality: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  numMembers: Maybe<Scalars["Float"]>;
  onboardingRoles: Maybe<Array<OnboardingRole>>;
  onboardingStep: Maybe<OnboardingStep>;
  organization: Organization;
  orgnr: Scalars["String"];
  role: Role;
  sales: SaleSettingsType;
  seatsInUse: Scalars["Float"];
  slug: Scalars["String"];
  totalSeats: Scalars["Float"];
  zip: Scalars["String"];
};

export type OrganizationBilling = {
  __typename: "OrganizationBilling";
  _id: Scalars["ObjectId"];
  details: Maybe<BillingDetails>;
  mode: BillingMode;
  organization: Scalars["ObjectId"];
  session: Maybe<Scalars["String"]>;
  status: BillingStatus;
};

export type OrganizationBillingAddress = {
  __typename: "OrganizationBillingAddress";
  city: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  line1: Maybe<Scalars["String"]>;
  line2: Maybe<Scalars["String"]>;
  postalCode: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
};

export type OrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  agreementAccepted?: InputMaybe<Scalars["Boolean"]>;
  billing?: InputMaybe<BillingAddressInput>;
  city?: InputMaybe<Scalars["String"]>;
  communication?: InputMaybe<CommunicationSettingsInput>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  createOrdersFromSales?: InputMaybe<Scalars["Boolean"]>;
  customer?: InputMaybe<CustomerSettingsInput>;
  hasLogo?: InputMaybe<Scalars["Boolean"]>;
  industry?: InputMaybe<Scalars["String"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  onboardingRoles?: InputMaybe<Array<OnboardingRole>>;
  onboardingStep?: InputMaybe<OnboardingStep>;
  orgnr?: InputMaybe<Scalars["String"]>;
  sales?: InputMaybe<SaleSettingsInput>;
  slug?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type OrganizationMeta = {
  __typename: "OrganizationMeta";
  config: Maybe<Scalars["JSONObject"]>;
  size: Maybe<OrganizationSize>;
};

export type OrganizationMetaInput = {
  size?: InputMaybe<OrganizationSizeInput>;
};

export type OrganizationSize = {
  __typename: "OrganizationSize";
  from: Scalars["Float"];
  to: Scalars["Float"];
};

export type OrganizationSizeInput = {
  from: Scalars["Float"];
  to: Scalars["Float"];
};

export type OrganizationSizeType = {
  __typename: "OrganizationSizeType";
  key: Scalars["String"];
  value: OrganizationSize;
};

export type PaginatedRespone = {
  __typename: "PaginatedRespone";
  data: Array<Event>;
  nextPageToken: Maybe<Scalars["String"]>;
};

export type PaymentDetails = {
  __typename: "PaymentDetails";
  address: Maybe<OrganizationBillingAddress>;
  email: Scalars["String"];
  name: Scalars["String"];
};

export type PaymentMethod = {
  __typename: "PaymentMethod";
  card: Card;
  details: PaymentDetails;
};

export type PaymentSettingsInput = {
  currencies?: InputMaybe<Array<Scalars["String"]>>;
  defaultCurrency?: InputMaybe<Scalars["String"]>;
  defaultFrequency?: InputMaybe<Scalars["String"]>;
};

export type PaymentSettingsType = {
  __typename: "PaymentSettingsType";
  currencies: Array<Scalars["String"]>;
  defaultCurrency: Scalars["String"];
  defaultFrequency: Scalars["String"];
};

export enum Period {
  lastMonth = "lastMonth",
  lastQuarter = "lastQuarter",
  lastWeek = "lastWeek",
  lastYear = "lastYear",
  nextMonth = "nextMonth",
  nextQuarter = "nextQuarter",
  nextWeek = "nextWeek",
  nextYear = "nextYear",
  pastSixMonths = "pastSixMonths",
  thisMonth = "thisMonth",
  thisQuarter = "thisQuarter",
  thisWeek = "thisWeek",
  thisYear = "thisYear",
  today = "today",
  tomorrow = "tomorrow",
  yesterday = "yesterday",
}

export type Person = {
  __typename: "Person";
  _id: Scalars["ObjectId"];
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  customer: Maybe<Customer>;
  customerCategory: Maybe<Array<CustomerCategory>>;
  customerStatus: Maybe<CustomerStatus>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  email: Maybe<Scalars["String"]>;
  favorite: Maybe<Scalars["Boolean"]>;
  lists: Maybe<Array<List>>;
  meta: Maybe<Meta>;
  name: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  role: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type PersonFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  createdAt?: InputMaybe<DateRange>;
  creatorId?: InputMaybe<Scalars["ObjectId"]>;
  customerCategory?: InputMaybe<Array<Scalars["ObjectId"]>>;
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  customerStatus?: InputMaybe<Array<Scalars["ObjectId"]>>;
  hasEmailAddress?: InputMaybe<Scalars["Boolean"]>;
  hasPhoneNumber?: InputMaybe<Scalars["Boolean"]>;
  userUpdatedAt?: InputMaybe<DateRange>;
};

export type PersonInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  creator?: InputMaybe<Scalars["ObjectId"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  email?: InputMaybe<Scalars["String"]>;
  favorite?: InputMaybe<Scalars["Boolean"]>;
  meta?: InputMaybe<MetaInput>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
};

export type PlanProduct = {
  __typename: "PlanProduct";
  active: Scalars["Boolean"];
  created: Scalars["Float"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["ObjectId"];
  image: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  updated: Scalars["Float"];
};

export type PositionInput = {
  h: Scalars["Float"];
  w: Scalars["Float"];
  x: Scalars["Float"];
  y: Scalars["Float"];
};

export type Price = {
  __typename: "Price";
  active: Scalars["Boolean"];
  billingScheme: BillingScheme;
  category: PriceCategory;
  created: Scalars["Float"];
  currency: Scalars["String"];
  id: Scalars["ObjectId"];
  lookupKey: Maybe<Scalars["String"]>;
  nickname: Maybe<Scalars["String"]>;
  product: PlanProduct;
  recurring: Maybe<Recurring>;
  type: PriceType;
  unitAmount: Maybe<Scalars["Float"]>;
  unitAmountDecimal: Maybe<Scalars["String"]>;
};

export enum PriceCategory {
  MODULE = "MODULE",
  SEAT = "SEAT",
}

export type PriceFilter = {
  active?: Scalars["Boolean"];
  currency?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["JSON"]>;
  product?: InputMaybe<Scalars["String"]>;
};

export enum PriceType {
  ONE_TIME = "ONE_TIME",
  RECURRING = "RECURRING",
}

export type Product = {
  __typename: "Product";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  creator: User;
  description: Maybe<Scalars["String"]>;
  hidden: Scalars["Boolean"];
  images: Maybe<Array<Scalars["String"]>>;
  isExpiring: Scalars["Boolean"];
  isPriceDynamic: Scalars["Boolean"];
  isRecurring: Scalars["Boolean"];
  name: Scalars["String"];
  position: Maybe<Scalars["String"]>;
  pricing: Array<ProductPrice>;
  recurringLength: Maybe<Scalars["Float"]>;
  recurringUnit: Maybe<ProductDurationUnit>;
  sku: Maybe<Scalars["String"]>;
  unit: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  updatedBy: Maybe<User>;
};

export enum ProductDurationUnit {
  day = "day",
  month = "month",
  year = "year",
}

export type ProductFilter = {
  currency?: InputMaybe<Scalars["String"]>;
  showHidden?: InputMaybe<Scalars["Boolean"]>;
};

export type ProductPrice = {
  __typename: "ProductPrice";
  currency: Currency;
  price: Scalars["Float"];
};

export type ProductPriceInput = {
  currency: Scalars["String"];
  price: Scalars["Float"];
};

export type ProductSettingsInput = {
  sku?: InputMaybe<Scalars["Boolean"]>;
};

export type ProductSettingsType = {
  __typename: "ProductSettingsType";
  sku: Scalars["Boolean"];
};

export type ProviderEventFilterInput = {
  account: Scalars["ObjectId"];
  calendar: Scalars["String"];
  customer?: InputMaybe<Scalars["ObjectId"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  start?: InputMaybe<Scalars["DateTime"]>;
};

export type Query = {
  __typename: "Query";
  activities: Array<Activity>;
  advancedFilter: AdvancedFilter;
  advancedFilters: Array<AdvancedFilter>;
  brregCustomer: Array<BrregResult>;
  conversation: Conversation;
  conversionRateInsight: Maybe<Scalars["String"]>;
  countCalendarAccounts: Scalars["Float"];
  countCustomer: Scalars["Int"];
  countMailerAccounts: Scalars["Float"];
  countNotifications: Scalars["Float"];
  countOrders: Scalars["Int"];
  countPeople: Scalars["Float"];
  countSale: Scalars["Float"];
  countTasks: Scalars["Float"];
  countTimeTrackings: Scalars["Float"];
  documentSignature: Signature;
  eventsGraphInsight: Maybe<Scalars["JSON"]>;
  file: File;
  files: Array<File>;
  getAbilities: Scalars["JSON"];
  getCalendars: Array<Calendar>;
  getConfig: BillingProviderConfig;
  getConfirmationTemplate: Template;
  getContract: Maybe<Contract>;
  getCustomFieldValue: Scalars["JSON"];
  getCustomFieldValues: Scalars["JSON"];
  getCustomFields: Array<CustomField>;
  getCustomer: Customer;
  getCustomerCategory: CustomerCategory;
  getCustomerRelations: Array<Customer>;
  getCustomerStatuses: Array<CustomerStatus>;
  getDashboard: Dashboard;
  getEvent: Event;
  getEventType: EventType;
  getEventTypes: Array<EventType>;
  getFilledContract: Maybe<Scalars["JSON"]>;
  getFlags: Array<Flag>;
  getFlagsByResource: Array<Flag>;
  getGridItem: GridItem;
  getIndustryTypes: Array<IndustryType>;
  getIntegratedCalendarAccounts: Array<CalendarIntegration>;
  getJobLevels: Array<JobLevel>;
  getList: List;
  getLists: Array<List>;
  getMailerAccounts: Array<MailerAccount>;
  getManyClient: Array<OidcClient>;
  getManyContracts: Array<Contract>;
  getManyConversations: Array<Conversation>;
  getManyCustomerCategories: Array<CustomerCategory>;
  getManyCustomers: Array<Customer>;
  getManyDashboards: Array<Dashboard>;
  getManyEvents: Array<Event>;
  getManyExport: Array<Exportable>;
  getManyImportHistories: Array<ImportHistoryType>;
  getManyLists: Array<List>;
  getManyOrderTasks: Array<OrderTask>;
  getManyPeople: Array<Person>;
  getManyProducts: Array<Product>;
  getManyRoles: Array<Role>;
  getManySales: Array<Sale>;
  getManySteps: Array<Step>;
  getManyTasks: Array<Task>;
  getManyTimeTrackings: Array<TimeTracking>;
  getManyUsers: Array<User>;
  getOrderTask: OrderTask;
  getOrganization: Organization;
  getOrganizationBilling: Maybe<OrganizationBilling>;
  getOrganizationBySlug: Organization;
  getOrganizationSizeOptions: Array<OrganizationSizeType>;
  getPerson: Person;
  getPossibleRelations: Array<Customer>;
  getProduct: Product;
  getProviderEvent: Event;
  getProviderManyEvents: PaginatedRespone;
  getRole: Array<Role>;
  getSale: Sale;
  getSections: SectionUnion;
  getSettings: SettingsUnion;
  getStatus: CustomerStatus;
  getStep: Step;
  getSubscriptionPlans: Array<Price>;
  getTask: Task;
  getTimeTracking: TimeTracking;
  getUser: User;
  getUserByEmail: User;
  getWhitelist: Array<Whitelist>;
  getWhitelistRecord: Whitelist;
  getWorkflow: Workflow;
  health: Scalars["Boolean"];
  introspection: Introspection;
  inviteUserGetMetadata: InvitationMetadata;
  listSharedResources: Array<Sharing>;
  listSharingResources: Array<Sharing>;
  listWorkflows: Array<Workflow>;
  notifications: Array<Notification>;
  order: Order;
  orders: Array<Order>;
  salesGraphInsight: Maybe<Scalars["JSON"]>;
  signatureAttachment: SignatureAttachment;
  signatureRequestTemplate: Template;
  storageUsage: Scalars["String"];
  sumTimeTrackings: Scalars["Float"];
  userSignature: Template;
  verifiedEmailAddresses: Array<VerifiedEmailAddress>;
  winningTimeInsight: Maybe<Scalars["JSON"]>;
};

export type QueryactivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QueryadvancedFilterArgs = {
  _id: Scalars["ObjectId"];
};

export type QueryadvancedFiltersArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerybrregCustomerArgs = {
  search: Scalars["String"];
};

export type QueryconversationArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerycountCalendarAccountsArgs = {
  filters?: InputMaybe<CalendarIntegrationFilters>;
};

export type QuerycountCustomerArgs = {
  countBy: CountCustomersBy;
  filter?: InputMaybe<CustomerFilter>;
  lists?: InputMaybe<SearchListFilterArgs>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QuerycountOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerycountPeopleArgs = {
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  filter?: InputMaybe<PersonFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerycountSaleArgs = {
  countBy: CountSalesBy;
  filter?: InputMaybe<SaleFilter>;
  filterId?: InputMaybe<Scalars["ObjectId"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QuerycountTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  search?: InputMaybe<Scalars["String"]>;
  step?: InputMaybe<Scalars["ObjectId"]>;
};

export type QuerycountTimeTrackingsArgs = {
  filter?: InputMaybe<TimeTrackingFilter>;
};

export type QuerydocumentSignatureArgs = {
  token: Scalars["String"];
};

export type QueryeventsGraphInsightArgs = {
  date: DateRange;
  interval: Interval;
};

export type QueryfileArgs = {
  _id: Scalars["ObjectId"];
};

export type QueryfilesArgs = {
  customerId: Scalars["ObjectId"];
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  orderId?: InputMaybe<Scalars["ObjectId"]>;
  saleId?: InputMaybe<Scalars["ObjectId"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetCalendarsArgs = {
  account?: InputMaybe<Scalars["ObjectId"]>;
  provider: CalendarProvider;
};

export type QuerygetContractArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetCustomFieldValueArgs = {
  domain: EntityName;
  field: Scalars["ObjectId"];
  resource: Scalars["ObjectId"];
};

export type QuerygetCustomFieldValuesArgs = {
  domain: EntityName;
  resource: Scalars["ObjectId"];
};

export type QuerygetCustomFieldsArgs = {
  domain: EntityName;
  order?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetCustomerArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetCustomerCategoryArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetCustomerRelationsArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetCustomerStatusesArgs = {
  filter?: InputMaybe<CustomerStatusFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetDashboardArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetEventArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetEventTypeArgs = {
  eventTypeId: Scalars["ObjectId"];
};

export type QuerygetEventTypesArgs = {
  filter?: InputMaybe<EventTypeFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetFilledContractArgs = {
  _id: Scalars["ObjectId"];
  placeholders: Scalars["JSON"];
};

export type QuerygetFlagsArgs = {
  domain: EntityName;
  kind?: InputMaybe<FlagKind>;
};

export type QuerygetFlagsByResourceArgs = {
  domain?: InputMaybe<EntityName>;
  kind?: InputMaybe<FlagKind>;
  resources: Array<Scalars["ObjectId"]>;
};

export type QuerygetGridItemArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetListArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetListsArgs = {
  domain: EntityName;
};

export type QuerygetManyClientArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyContractsArgs = {
  filter?: InputMaybe<TemplateInputFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyConversationsArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyCustomerCategoriesArgs = {
  filter?: InputMaybe<CustomerCategoryFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyCustomersArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<CustomerFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyEventsArgs = {
  filter?: InputMaybe<EventFilter>;
};

export type QuerygetManyExportArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyImportHistoriesArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyListsArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<ListFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyPeopleArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  filter?: InputMaybe<PersonFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManySalesArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<SaleFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyStepsArgs = {
  limit?: InputMaybe<Scalars["Float"]>;
  skip?: InputMaybe<Scalars["Float"]>;
  workflow?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type QuerygetManyTasksArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<TaskFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  step?: InputMaybe<Scalars["ObjectId"]>;
  workflow?: InputMaybe<Scalars["ObjectId"]>;
};

export type QuerygetManyTimeTrackingsArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<TimeTrackingFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetManyUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetOrderTaskArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetOrganizationBillingArgs = {
  organization: Scalars["ObjectId"];
};

export type QuerygetOrganizationBySlugArgs = {
  slug: Scalars["String"];
};

export type QuerygetPersonArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetPossibleRelationsArgs = {
  customerId?: InputMaybe<Scalars["ObjectId"]>;
  filter?: InputMaybe<CustomerFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  relation: RelationType;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerygetProductArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetProviderEventArgs = {
  account: Scalars["ObjectId"];
  calendar: Scalars["String"];
  id: Scalars["String"];
};

export type QuerygetProviderManyEventsArgs = {
  filters: ProviderEventFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
};

export type QuerygetRoleArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetSaleArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetSectionsArgs = {
  by: TASK_SECTIONING_OPTIONS;
  filter?: InputMaybe<TaskFilter>;
  limit?: InputMaybe<Scalars["Float"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Float"]>;
  skipEmpty?: InputMaybe<Scalars["Boolean"]>;
};

export type QuerygetSettingsArgs = {
  area?: InputMaybe<EntityName>;
  domain?: InputMaybe<EntityName>;
  global?: InputMaybe<Scalars["Boolean"]>;
  resource?: InputMaybe<Scalars["ObjectId"]>;
};

export type QuerygetStatusArgs = {
  statusId: Scalars["ObjectId"];
};

export type QuerygetStepArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetSubscriptionPlansArgs = {
  filters: PriceFilter;
};

export type QuerygetTaskArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetTimeTrackingArgs = {
  _id: Scalars["ObjectId"];
};

export type QuerygetUserArgs = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
};

export type QuerygetUserByEmailArgs = {
  email: Scalars["String"];
};

export type QuerygetWhitelistRecordArgs = {
  recordId: Scalars["ObjectId"];
};

export type QuerygetWorkflowArgs = {
  _id: Scalars["ObjectId"];
};

export type QueryhealthArgs = {
  error?: Scalars["Boolean"];
  timeout?: Scalars["Float"];
};

export type QueryinviteUserGetMetadataArgs = {
  code: Scalars["String"];
};

export type QuerylistSharedResourcesArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<EntityName>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  resource?: InputMaybe<Scalars["ObjectId"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerylistSharingResourcesArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<EntityName>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  resource?: InputMaybe<Scalars["ObjectId"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerynotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QueryorderArgs = {
  _id: Scalars["ObjectId"];
};

export type QueryordersArgs = {
  advanced?: InputMaybe<FilterArgsInput>;
  filter?: InputMaybe<OrderFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type QuerysalesGraphInsightArgs = {
  creator?: InputMaybe<Array<Scalars["ObjectId"]>>;
  date: SalesInsightDateRange;
  groupBy?: InputMaybe<SaleFilterPipelineBetweenProperty>;
  interval: Interval;
  probability?: InputMaybe<SaleProbabilityFilter>;
  status?: InputMaybe<SaleStatus>;
};

export type QuerysignatureAttachmentArgs = {
  attachment: Scalars["ObjectId"];
  token: Scalars["String"];
};

export type QuerysumTimeTrackingsArgs = {
  filter?: InputMaybe<TimeTrackingFilter>;
};

export type RawTimeTrackingInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  comment?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  day: Scalars["String"];
  meta?: InputMaybe<MetaInput>;
  order?: InputMaybe<Scalars["ObjectId"]>;
  sale?: InputMaybe<Scalars["ObjectId"]>;
  time: Scalars["Float"];
  title?: InputMaybe<Scalars["String"]>;
};

export type RealtimeActivityFilter = {
  __typename: "RealtimeActivityFilter";
  activityTypes: Maybe<Array<ACTIVITY_TYPE>>;
  user: Maybe<Scalars["ObjectId"]>;
};

export type Recurring = {
  __typename: "Recurring";
  interval: BillingInterval;
};

export type RegisterUserInput = {
  email: Scalars["String"];
  language?: InputMaybe<Language>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone: Scalars["String"];
  position?: InputMaybe<UserPosition>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type RegistrationMetadata = {
  __typename: "RegistrationMetadata";
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  expireAfterFirstUse: Scalars["Boolean"];
  key: Scalars["String"];
  metadata: RegistrationMetadataPayload;
  ttl: Maybe<Scalars["Float"]>;
};

export type RegistrationMetadataPayload = {
  __typename: "RegistrationMetadataPayload";
  user: RegistrationMetadataPayloadUser;
};

export type RegistrationMetadataPayloadUser = {
  __typename: "RegistrationMetadataPayloadUser";
  email: Scalars["String"];
  language: Maybe<Language>;
  name: Scalars["String"];
  phone: Scalars["String"];
  position: Maybe<JobLevel>;
  timezone: Maybe<Scalars["String"]>;
};

export type RelatedListFilterArgs = {
  __typename: "RelatedListFilterArgs";
  and: Maybe<Array<Scalars["ObjectId"]>>;
  not: Maybe<Array<Scalars["ObjectId"]>>;
  or: Maybe<Array<Scalars["ObjectId"]>>;
};

export type RelatedListFilterArgsInput = {
  and?: InputMaybe<Array<Scalars["ObjectId"]>>;
  not?: InputMaybe<Array<Scalars["ObjectId"]>>;
  or?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type RelationActivity = Activity & {
  __typename: "RelationActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  targetUnit: Customer;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export enum RelationType {
  child = "child",
  parent = "parent",
}

export type RevertImportResult = {
  __typename: "RevertImportResult";
  revertedCustomers: Scalars["Float"];
  revertedImport: ImportHistoryType;
  revertedNotes: Scalars["Float"];
  revertedPeople: Scalars["Float"];
};

export type Role = {
  __typename: "Role";
  _id: Scalars["ObjectId"];
  abilities: Array<Ability>;
  client: Maybe<OidcClient>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["ObjectId"];
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<Scalars["ObjectId"]>;
  name: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  users: Array<User>;
};

export enum STEP_STATE {
  FINISHED = "FINISHED",
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  WONT_DO = "WONT_DO",
}

export type Sale = {
  __typename: "Sale";
  _id: Scalars["ObjectId"];
  attachments: Maybe<Array<File>>;
  baseCurrency: Currency;
  basket: Array<BasketItem>;
  billingFrequency: Maybe<BillingFrequency>;
  closedAt: Maybe<Scalars["DateTime"]>;
  comment: Maybe<Scalars["String"]>;
  contract: Maybe<Contract>;
  createdAt: Scalars["DateTime"];
  creator: User;
  currency: Currency;
  currencyDiscount: Scalars["Float"];
  currencySubtotal: Scalars["Float"];
  customer: Customer;
  discount: Scalars["Float"];
  expectedAt: Maybe<Scalars["DateTime"]>;
  expiresAt: Maybe<Scalars["DateTime"]>;
  hasOrder: Scalars["Boolean"];
  label: Maybe<Scalars["String"]>;
  language: Language;
  lists: Maybe<Array<List>>;
  lostAt: Maybe<Scalars["DateTime"]>;
  manuallySignedAt: Maybe<Scalars["DateTime"]>;
  meta: Maybe<Meta>;
  monthlyPrice: Scalars["Float"];
  order: Maybe<Order>;
  probability: Maybe<Scalars["Int"]>;
  qty: Scalars["Float"];
  ref: Maybe<Scalars["Int"]>;
  saleStatus: Scalars["String"];
  shouldSign: Scalars["Boolean"];
  signature: Maybe<Signature>;
  signee: Maybe<Person>;
  startsAt: Maybe<Scalars["DateTime"]>;
  status: Maybe<SaleStatus>;
  statusHistory: Maybe<Array<SaleStatusHistory>>;
  subtotal: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
  userUpdatedAt: Scalars["DateTime"];
};

export type SaleActivity = Activity & {
  __typename: "SaleActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  currency: Currency;
  currencySubtotal: Scalars["Float"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  ref: Scalars["Int"];
  sale: Sale;
  signee: Maybe<Person>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type SaleActivityWithExpectedDate = Activity & {
  __typename: "SaleActivityWithExpectedDate";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  currency: Currency;
  currencySubtotal: Scalars["Float"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  next: Maybe<Scalars["DateTime"]>;
  prev: Maybe<Scalars["DateTime"]>;
  ref: Scalars["Int"];
  sale: Sale;
  signee: Maybe<Person>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type SaleActivityWithProbability = Activity & {
  __typename: "SaleActivityWithProbability";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  currency: Currency;
  currencySubtotal: Scalars["Float"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  next: Maybe<Scalars["Int"]>;
  prev: Maybe<Scalars["Int"]>;
  ref: Scalars["Int"];
  sale: Sale;
  signee: Maybe<Person>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type SaleActivityWithStatus = Activity & {
  __typename: "SaleActivityWithStatus";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  currency: Currency;
  currencySubtotal: Scalars["Float"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  next: Maybe<SaleStatus>;
  prev: Maybe<SaleStatus>;
  ref: Scalars["Int"];
  sale: Sale;
  signee: Maybe<Person>;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type SaleDraftInput = {
  baseCurrency?: InputMaybe<Currency>;
  basket?: InputMaybe<Array<BasketInput>>;
  billingFrequency?: InputMaybe<BillingFrequency>;
  comment?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["ObjectId"]>;
  currency?: InputMaybe<Currency>;
  currencyDiscount?: Scalars["Float"];
  currencySubtotal?: InputMaybe<Scalars["Float"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  discount?: Scalars["Float"];
  expectedAt?: InputMaybe<Scalars["DateTime"]>;
  filesToIncludeInSigning?: InputMaybe<Array<Scalars["ObjectId"]>>;
  label?: InputMaybe<Scalars["String"]>;
  language?: Language;
  lostAt?: InputMaybe<Scalars["DateTime"]>;
  manuallySignedAt?: InputMaybe<Scalars["DateTime"]>;
  meta?: InputMaybe<MetaInput>;
  probability?: InputMaybe<Scalars["Int"]>;
  shouldSign?: Scalars["Boolean"];
  signee?: InputMaybe<Scalars["ObjectId"]>;
  status?: InputMaybe<SaleStatus>;
  subtotal?: InputMaybe<Scalars["Float"]>;
};

export type SaleFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  between?: InputMaybe<SaleFilterBetween>;
  closedAt?: InputMaybe<DateRange>;
  contract?: InputMaybe<Array<SaleFilterContract>>;
  createdAt?: InputMaybe<DateRange>;
  creator?: InputMaybe<Array<Scalars["ObjectId"]>>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  expectedAt?: InputMaybe<DateRange>;
  expirationDate?: InputMaybe<DateRange>;
  hasOrder?: InputMaybe<Scalars["Boolean"]>;
  isSigned?: InputMaybe<Scalars["Boolean"]>;
  pipeline?: InputMaybe<SaleFilterPipeline>;
  products?: InputMaybe<Array<Scalars["ObjectId"]>>;
  propertyExists?: InputMaybe<Array<Scalars["String"]>>;
  startsDate?: InputMaybe<DateRange>;
  status?: InputMaybe<Array<SaleStatus>>;
  userUpdatedAt?: InputMaybe<DateRange>;
};

export type SaleFilterBetween = {
  end: Scalars["String"];
  property: SaleFilterBetweenProperty;
  start: Scalars["String"];
};

export enum SaleFilterBetweenProperty {
  createdAt = "createdAt",
  lostAt = "lostAt",
  signedAt = "signedAt",
  updatedAt = "updatedAt",
}

export enum SaleFilterContract {
  lost = "lost",
  manuallySigned = "manuallySigned",
  notSigned = "notSigned",
  signed = "signed",
}

export type SaleFilterPipeline = {
  isBetween?: InputMaybe<SaleFilterPipelineBetween>;
  probability?: InputMaybe<SaleProbabilityFilter>;
  status?: InputMaybe<Array<SaleStatus>>;
};

export type SaleFilterPipelineBetween = {
  end?: InputMaybe<Scalars["String"]>;
  property: Array<SaleFilterPipelineBetweenProperty>;
  start?: InputMaybe<Scalars["String"]>;
};

export enum SaleFilterPipelineBetweenProperty {
  closedAt = "closedAt",
  expectedAt = "expectedAt",
  forcastedAt = "forcastedAt",
  lostAt = "lostAt",
  offeredAt = "offeredAt",
}

export type SaleInput = {
  baseCurrency: Currency;
  basket: Array<BasketInput>;
  billingFrequency?: InputMaybe<BillingFrequency>;
  comment?: InputMaybe<Scalars["String"]>;
  contract: Scalars["ObjectId"];
  currency: Currency;
  currencyDiscount?: Scalars["Float"];
  currencySubtotal: Scalars["Float"];
  customer: Scalars["ObjectId"];
  discount?: Scalars["Float"];
  expectedAt: Scalars["DateTime"];
  filesToIncludeInSigning?: InputMaybe<Array<Scalars["ObjectId"]>>;
  label?: InputMaybe<Scalars["String"]>;
  language?: Language;
  lostAt?: InputMaybe<Scalars["DateTime"]>;
  manuallySignedAt?: InputMaybe<Scalars["DateTime"]>;
  meta?: InputMaybe<MetaInput>;
  probability: Scalars["Int"];
  shouldSign?: Scalars["Boolean"];
  signee?: InputMaybe<Scalars["ObjectId"]>;
  status?: InputMaybe<SaleStatus>;
  subtotal: Scalars["Float"];
};

export type SaleNotification = Notification & {
  __typename: "SaleNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  sale: Sale;
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type SalePipelineFilter = {
  __typename: "SalePipelineFilter";
  creator: Maybe<Array<Scalars["ObjectId"]>>;
  date: Maybe<DateRangeType>;
  probability: Maybe<Scalars["Float"]>;
};

export type SaleProbabilityFilter = {
  max?: InputMaybe<Scalars["Int"]>;
  min?: InputMaybe<Scalars["Int"]>;
};

export type SaleSection = {
  __typename: "SaleSection";
  by: TASK_SECTIONING_OPTIONS;
  count: Scalars["Float"];
  sections: Array<SaleSectionMetadata>;
};

export type SaleSectionMetadata = {
  __typename: "SaleSectionMetadata";
  count: Scalars["Float"];
  payload: Sale;
};

export type SaleSettingsInput = {
  contract?: InputMaybe<ContractSettingsInput>;
  payment?: InputMaybe<PaymentSettingsInput>;
  product?: InputMaybe<ProductSettingsInput>;
};

export type SaleSettingsType = {
  __typename: "SaleSettingsType";
  contract: ContractSettingsType;
  payment: PaymentSettingsType;
  product: ProductSettingsType;
  signatureRequestTemplate: Maybe<Scalars["ObjectId"]>;
};

export enum SaleStatus {
  closed = "closed",
  draft = "draft",
  forecast = "forecast",
  lost = "lost",
  offer = "offer",
}

export type SaleStatusHistory = {
  __typename: "SaleStatusHistory";
  date: Scalars["DateTime"];
  isAuto: Scalars["Boolean"];
  status: Scalars["String"];
  user: User;
};

export type SalesGraphFilter = {
  __typename: "SalesGraphFilter";
  period: Maybe<Period>;
};

export type SalesInsightDateRange = {
  end?: InputMaybe<Scalars["String"]>;
  eventTypes?: InputMaybe<Array<Scalars["ObjectId"]>>;
  start?: InputMaybe<Scalars["String"]>;
};

export type SearchListFilterArgs = {
  and?: InputMaybe<Array<Scalars["ObjectId"]>>;
  not?: InputMaybe<Array<Scalars["ObjectId"]>>;
  or?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type SectionMetadata = {
  __typename: "SectionMetadata";
  count: Scalars["Float"];
  payload: SectionUnion;
};

export type SectionUnion =
  | CustomerSection
  | DateSection
  | OrderSection
  | SaleSection
  | StepSection
  | UserSection;

export type Settings = {
  __typename: "Settings";
  _id: Maybe<Scalars["ObjectId"]>;
  area: Maybe<EntityName>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  domain: Maybe<EntityName>;
  payload: Maybe<Scalars["JSON"]>;
  resource: Maybe<Scalars["ObjectId"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<Scalars["ObjectId"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type SettingsUnion =
  | CalendarSettings
  | CustomerSettings
  | NotificationSettings
  | Settings
  | TaskSettings;

export type SharedResourceUnionType = DefaultSharedResourceUninonType | List;

export type Sharing = {
  __typename: "Sharing";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["ObjectId"];
  kind: Scalars["String"];
  resource: Scalars["ObjectId"];
  user: User;
};

export type SharingNotification = Notification & {
  __typename: "SharingNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  kind: EntityName;
  resource: Maybe<SharedResourceUnionType>;
  revokedAt: Scalars["DateTime"];
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type SharingType = {
  __typename: "SharingType";
  _id: Scalars["ObjectId"];
  count: Scalars["Float"];
  private: Scalars["Boolean"];
  protected: Scalars["Boolean"];
  public: Scalars["Boolean"];
  sharing: Array<Sharing>;
};

export type SharingTypesharingArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type Signature = {
  __typename: "Signature";
  _id: Scalars["ObjectId"];
  attachments: Array<SignatureAttachment>;
  createdAt: Scalars["DateTime"];
  document: Scalars["String"];
  documentUrl: Scalars["String"];
  expired: Scalars["Boolean"];
  expiredBy: Maybe<Scalars["ObjectId"]>;
  isSigned: Scalars["Boolean"];
  language: Language;
  organization: Organization;
  participants: Array<SignatureParty>;
  reference: Scalars["Float"];
  signedAt: Maybe<Scalars["DateTime"]>;
};

export type SignatureAttachment = {
  __typename: "SignatureAttachment";
  _id: Scalars["ObjectId"];
  filetype: Scalars["String"];
  name: Scalars["String"];
  signature: Array<SignatureAttachment>;
  url: Maybe<Scalars["String"]>;
};

export type SignatureParty = {
  __typename: "SignatureParty";
  id: Scalars["ObjectId"];
  ip: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  representing: SignatureRepresentant;
  signedAt: Maybe<Scalars["DateTime"]>;
  userAgent: Maybe<Scalars["String"]>;
};

export enum SignatureRepresentant {
  customer = "customer",
  organization = "organization",
}

export type Step = {
  __typename: "Step";
  _id: Scalars["ObjectId"];
  color: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  default: Scalars["Boolean"];
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  name: Scalars["String"];
  position: Scalars["String"];
  state: STEP_STATE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
  workflow: Scalars["ObjectId"];
};

export type StepInput = {
  color?: InputMaybe<Scalars["String"]>;
  default?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  state: STEP_STATE;
};

export type StepSection = {
  __typename: "StepSection";
  by: TASK_SECTIONING_OPTIONS;
  count: Scalars["Float"];
  sections: Array<StepSectionMetadata>;
};

export type StepSectionMetadata = {
  __typename: "StepSectionMetadata";
  count: Scalars["Float"];
  payload: Step;
};

export type SyncOptions = {
  __typename: "SyncOptions";
  syncIncoming: Maybe<Scalars["Boolean"]>;
  syncSent: Maybe<Scalars["Boolean"]>;
};

export enum TASK_SECTIONING_OPTIONS {
  ASSIGNEE = "ASSIGNEE",
  CUSTOMER = "CUSTOMER",
  DUE_BY_DAY = "DUE_BY_DAY",
  DUE_BY_MONTH = "DUE_BY_MONTH",
  DUE_BY_QUARTER = "DUE_BY_QUARTER",
  DUE_BY_WEEK = "DUE_BY_WEEK",
  DUE_BY_YEAR = "DUE_BY_YEAR",
  DUE_SINCE_TODAY = "DUE_SINCE_TODAY",
  ORDER = "ORDER",
  SALE = "SALE",
  STATUS = "STATUS",
}

export enum TASK_SHOW_DONE_SINCE_OPTIONS {
  ALWAYS = "ALWAYS",
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TODAY = "TODAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum TASK_SORTING_OPTIONS {
  ASSIGNEE = "ASSIGNEE",
  CREATION = "CREATION",
  DUE = "DUE",
  MANUAL = "MANUAL",
  STATUS = "STATUS",
  UPDATED = "UPDATED",
}

export enum TASK_TILE_FIELD_OPTIONS {
  ASSIGNED_USERS = "ASSIGNED_USERS",
  CUSTOMER = "CUSTOMER",
  DUE_AT = "DUE_AT",
  ORDER = "ORDER",
  SALE = "SALE",
  STATUS = "STATUS",
  TIME_SPENT_IN_STATUS = "TIME_SPENT_IN_STATUS",
  TIME_TRACKED = "TIME_TRACKED",
}

export type Task = {
  __typename: "Task";
  _id: Scalars["ObjectId"];
  assigned: Maybe<Array<User>>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  description: Maybe<Scalars["String"]>;
  done: Scalars["Boolean"];
  doneAt: Maybe<Scalars["DateTime"]>;
  dueAt: Maybe<Scalars["DateTime"]>;
  lists: Maybe<Array<List>>;
  meta: Maybe<Meta>;
  order: Maybe<Order>;
  position: Maybe<Scalars["String"]>;
  sale: Maybe<Sale>;
  step: Scalars["ObjectId"];
  title: Scalars["String"];
  totalTrackedTime: Scalars["Float"];
  updatedAt: Maybe<Scalars["DateTime"]>;
  workflow: Scalars["ObjectId"];
};

export type TaskActivity = Activity & {
  __typename: "TaskActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customUser: Maybe<User>;
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  order: Maybe<Order>;
  task: Task;
  title: Scalars["String"];
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type TaskActivityWithDueDate = Activity & {
  __typename: "TaskActivityWithDueDate";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customUser: Maybe<User>;
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  next: Maybe<Scalars["DateTime"]>;
  order: Maybe<Order>;
  prev: Maybe<Scalars["DateTime"]>;
  task: Task;
  title: Scalars["String"];
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type TaskActivityWithWorkflow = Activity & {
  __typename: "TaskActivityWithWorkflow";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customUser: Maybe<User>;
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  next: Maybe<Step>;
  order: Maybe<Order>;
  prev: Maybe<Step>;
  task: Task;
  title: Scalars["String"];
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type TaskFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  assigned?: InputMaybe<Array<Array<Scalars["ObjectId"]>>>;
  createdAt?: InputMaybe<DateRange>;
  customer?: InputMaybe<Array<Scalars["ObjectId"]>>;
  done?: InputMaybe<Scalars["Boolean"]>;
  doneAt?: InputMaybe<DateRange>;
  dueAt?: InputMaybe<DateRange>;
  hasDueDate?: InputMaybe<Scalars["Boolean"]>;
  isAssigned?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Array<Scalars["ObjectId"]>>;
  sale?: InputMaybe<Array<Scalars["ObjectId"]>>;
  userUpdatedAt?: InputMaybe<DateRange>;
  withCustomer?: InputMaybe<Scalars["Boolean"]>;
  withOrder?: InputMaybe<Scalars["Boolean"]>;
  withSale?: InputMaybe<Scalars["Boolean"]>;
};

export type TaskInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  description?: InputMaybe<Scalars["String"]>;
  dueAt?: InputMaybe<Scalars["DateTime"]>;
  meta?: InputMaybe<MetaInput>;
  order?: InputMaybe<Scalars["ObjectId"]>;
  sale?: InputMaybe<Scalars["ObjectId"]>;
  title: Scalars["String"];
};

export type TaskNotification = Notification & {
  __typename: "TaskNotification";
  _id: Scalars["ObjectId"];
  channel: NotificationChannel;
  createdAt: Scalars["DateTime"];
  createdBy: Maybe<User>;
  isRead: Scalars["Boolean"];
  isSent: Scalars["Boolean"];
  sendAt: Maybe<Scalars["DateTime"]>;
  sentAt: Maybe<Scalars["DateTime"]>;
  task: Task;
  type: NOTIFICATION_TYPE;
  updatedAt: Scalars["DateTime"];
  user: User;
};

export type TaskSettings = {
  __typename: "TaskSettings";
  _id: Maybe<Scalars["ObjectId"]>;
  area: Maybe<EntityName>;
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  domain: Maybe<EntityName>;
  payload: TaskSettingsPayload;
  resource: Maybe<Scalars["ObjectId"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<Scalars["ObjectId"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type TaskSettingsPayload = {
  __typename: "TaskSettingsPayload";
  custom: Array<Scalars["ObjectId"]>;
  done: TASK_SHOW_DONE_SINCE_OPTIONS;
  fields: Array<TASK_TILE_FIELD_OPTIONS>;
  section: TASK_SECTIONING_OPTIONS;
  sort: TASK_SORTING_OPTIONS;
};

export type TaskSettingsPayloadInput = {
  custom?: Array<Scalars["ObjectId"]>;
  done: TASK_SHOW_DONE_SINCE_OPTIONS;
  fields: Array<TASK_TILE_FIELD_OPTIONS>;
  section: TASK_SECTIONING_OPTIONS;
  sort: TASK_SORTING_OPTIONS;
};

export type Template = {
  __typename: "Template";
  _id: Maybe<Scalars["ObjectId"]>;
  content: Scalars["JSON"];
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<Scalars["ObjectId"]>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<Scalars["ObjectId"]>;
  description: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  updatedBy: Maybe<Scalars["ObjectId"]>;
  user: Maybe<Scalars["ObjectId"]>;
};

export type TemplateInputFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type TimeTracking = {
  __typename: "TimeTracking";
  _id: Scalars["ObjectId"];
  comment: Scalars["String"];
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  customer: Maybe<Customer>;
  day: Scalars["String"];
  deletedAt: Maybe<Scalars["DateTime"]>;
  lists: Maybe<Array<List>>;
  meta: Maybe<Meta>;
  order: Maybe<Order>;
  sale: Maybe<Sale>;
  task: Maybe<Task>;
  time: Scalars["Float"];
  title: Maybe<Scalars["String"]>;
  totalTrackedTime: Scalars["Float"];
  updatedAt: Maybe<Scalars["DateTime"]>;
};

export type TimeTrackingActivity = Activity & {
  __typename: "TimeTrackingActivity";
  _id: Scalars["ObjectId"];
  createdAt: Scalars["DateTime"];
  customer: Maybe<Customer>;
  deletedAt: Maybe<Scalars["DateTime"]>;
  order: Maybe<Order>;
  sale: Maybe<Sale>;
  task: Maybe<Task>;
  time: Scalars["Float"];
  timeTracking: TimeTracking;
  type: ACTIVITY_TYPE;
  updatedAt: Maybe<Scalars["DateTime"]>;
  user: Maybe<User>;
};

export type TimeTrackingFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
  createdAt?: InputMaybe<DateRange>;
  customer?: InputMaybe<Array<Scalars["ObjectId"]>>;
  day?: InputMaybe<DateRangeString>;
  order?: InputMaybe<Array<Scalars["ObjectId"]>>;
  sale?: InputMaybe<Array<Scalars["ObjectId"]>>;
  task?: InputMaybe<Array<Scalars["ObjectId"]>>;
  user?: InputMaybe<Array<Scalars["ObjectId"]>>;
  userUpdatedAt?: InputMaybe<DateRange>;
};

export type TimeTrackingInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  comment?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  day: Scalars["String"];
  meta?: InputMaybe<MetaInput>;
  order?: InputMaybe<Scalars["ObjectId"]>;
  sale?: InputMaybe<Scalars["ObjectId"]>;
  task?: InputMaybe<Scalars["ObjectId"]>;
  time: Scalars["Float"];
  title?: InputMaybe<Scalars["String"]>;
};

export type Tracking = {
  __typename: "Tracking";
  carrierName: Maybe<Scalars["String"]>;
  trackingNumber: Maybe<Scalars["String"]>;
};

export type UpdateCustomFieldInput = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  options?: InputMaybe<CustomFieldOptionsInput>;
  required?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateCustomerInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  address?: InputMaybe<Scalars["String"]>;
  assigned?: InputMaybe<Array<Scalars["ObjectId"]>>;
  billing?: InputMaybe<BillingInput>;
  business?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Array<Scalars["ObjectId"]>>;
  city?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["ObjectId"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  creator?: InputMaybe<Scalars["ObjectId"]>;
  customerNr?: InputMaybe<Scalars["String"]>;
  meta?: InputMaybe<MetaInput>;
  municipality?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  orgnr?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationType>;
  status?: InputMaybe<Scalars["ObjectId"]>;
  targetUnit?: InputMaybe<Scalars["ObjectId"]>;
  type?: InputMaybe<CustomerType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerStatus = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  color?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  name: Scalars["String"];
};

export type UpdateEventInput = {
  attendees?: InputMaybe<Array<AttendeeInput>>;
  calendar?: InputMaybe<Scalars["ObjectIdOrString"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]>;
  comment?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ObjectId"]>;
  description?: InputMaybe<Scalars["String"]>;
  doneAt?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  eventType?: InputMaybe<Scalars["ObjectId"]>;
  host?: InputMaybe<Array<Scalars["ObjectId"]>>;
  meta?: InputMaybe<MetaInput>;
  participants?: InputMaybe<Array<Scalars["ObjectId"]>>;
  start?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateEventType = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  borderColor: EventTypesBorderColor;
  color: EventTypesColor;
  name: Scalars["String"];
  type: EventTypes;
};

export type UpdateGridInput = {
  _id?: InputMaybe<Scalars["ObjectId"]>;
  data?: InputMaybe<GridDataInput>;
  element?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<GridFilterInput>;
  name?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<PositionInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateListInput = {
  description?: InputMaybe<Scalars["String"]>;
  dynamic?: InputMaybe<Scalars["Boolean"]>;
  emoji?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ListFilterInput>;
  lists?: InputMaybe<RelatedListFilterArgsInput>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderInput = {
  customSale?: InputMaybe<Scalars["JSON"]>;
  deliveryAddress?: InputMaybe<Scalars["ObjectId"]>;
  meta?: InputMaybe<MetaInput>;
  orderContactPerson?: InputMaybe<Scalars["ObjectId"]>;
  saleContactPerson?: InputMaybe<Scalars["ObjectId"]>;
  tracking?: InputMaybe<UpdateTrackingInput>;
};

export type UpdateProductInput = {
  description?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  images?: InputMaybe<Array<Scalars["String"]>>;
  isExpiring?: Scalars["Boolean"];
  isPriceDynamic?: Scalars["Boolean"];
  isRecurring?: Scalars["Boolean"];
  name?: InputMaybe<Scalars["String"]>;
  pricing?: InputMaybe<Array<ProductPriceInput>>;
  recurringLength?: InputMaybe<Scalars["Float"]>;
  recurringUnit?: InputMaybe<ProductDurationUnit>;
  sku?: InputMaybe<Scalars["String"]>;
  unit: Scalars["String"];
};

export type UpdateRoleInput = {
  name: Scalars["String"];
  users?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export type UpdateSubscriptionInput = {
  id: Scalars["String"];
  quantity: Scalars["Float"];
};

export type UpdateTrackingInput = {
  carrierName?: InputMaybe<Scalars["String"]>;
  trackingNumber?: InputMaybe<Scalars["String"]>;
};

export type UpdateUser = {
  email?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Language>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  twoFactor?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateWorkflowInput = {
  default?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename: "User";
  _id: Scalars["ObjectId"];
  abilities: Maybe<Scalars["JSON"]>;
  client: Maybe<OidcClient>;
  countEvents: Scalars["Float"];
  countSales: Scalars["Float"];
  email: Scalars["String"];
  events: Array<Event>;
  language: Scalars["String"];
  name: Scalars["String"];
  organization: Maybe<Organization>;
  organizations: Array<Organization>;
  phone: Maybe<Scalars["String"]>;
  position: Maybe<JobLevel>;
  role: Maybe<Role>;
  sales: Array<Sale>;
  timezone: Scalars["String"];
  twoFactor: Scalars["Boolean"];
};

export type UsercountEventsArgs = {
  countBy?: InputMaybe<CountEventsBy>;
  distinctBy?: InputMaybe<CountEventsDistinctBy>;
  filter?: InputMaybe<EventFilter>;
};

export type UsercountSalesArgs = {
  countBy: CountSalesBy;
  filter?: InputMaybe<SaleFilter>;
  filterId?: InputMaybe<Scalars["ObjectId"]>;
  lists?: InputMaybe<SearchListFilterArgs>;
  search?: InputMaybe<Scalars["String"]>;
};

export type UsereventsArgs = {
  filter?: InputMaybe<EventFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type UserorganizationsArgs = {
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type UsersalesArgs = {
  filter?: InputMaybe<SaleFilter>;
  fuzzy?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageToken?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
};

export type UserFilter = {
  _id?: InputMaybe<Array<Scalars["ObjectId"]>>;
};

export enum UserPosition {
  Administrative_Assistant = "Administrative_Assistant",
  CEO = "CEO",
  Customer_Support_Agent = "Customer_Support_Agent",
  Human_Resources = "Human_Resources",
  Manager = "Manager",
  Marketing_Specialist = "Marketing_Specialist",
  Project_manager = "Project_manager",
  Sales_Representative = "Sales_Representative",
  Software_Developer = "Software_Developer",
  Team_Leader = "Team_Leader",
  UNKNOWN = "UNKNOWN",
}

export type UserSection = {
  __typename: "UserSection";
  by: TASK_SECTIONING_OPTIONS;
  count: Scalars["Float"];
  sections: Array<UserSectionMetadata>;
};

export type UserSectionMetadata = {
  __typename: "UserSectionMetadata";
  count: Scalars["Float"];
  payload: User;
};

export type VerifiedEmailAddress = {
  __typename: "VerifiedEmailAddress";
  _id: Scalars["ObjectId"];
  address: Scalars["String"];
  createdAt: Scalars["DateTime"];
  isDefault: Scalars["Boolean"];
  isDomainVerified: Maybe<Scalars["Boolean"]>;
  isVerified: Scalars["Boolean"];
};

export type Whitelist = {
  __typename: "Whitelist";
  _id: Scalars["ObjectId"];
  address: Scalars["String"];
};

export type WhitelistInput = {
  address: Scalars["String"];
};

export type Workflow = {
  __typename: "Workflow";
  _id: Scalars["ObjectId"];
  createdAt: Maybe<Scalars["DateTime"]>;
  creator: Maybe<User>;
  default: Scalars["Boolean"];
  deletedAt: Maybe<Scalars["DateTime"]>;
  deletedBy: Maybe<User>;
  description: Maybe<Scalars["String"]>;
  lists: Maybe<Array<List>>;
  name: Scalars["String"];
  steps: Array<Step>;
  updatedAt: Maybe<Scalars["DateTime"]>;
  userUpdatedAt: Maybe<Scalars["DateTime"]>;
};

export type WorkflowInput = {
  default?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type authIntrospection = {
  __typename: "authIntrospection";
  createRole: IntrospectionPayload;
  createTenant: IntrospectionPayload;
  dangerouslyHardDeleteOrganization: IntrospectionPayload;
  dangerouslyResetOrganization: IntrospectionPayload;
  deleteRole: IntrospectionPayload;
  getAbilities: IntrospectionPayload;
  getUserByEmail: IntrospectionPayload;
  inviteUser: IntrospectionPayload;
  lockOrganization: IntrospectionPayload;
  manuallyApplyOnboarding: IntrospectionPayload;
  registerOrganization: IntrospectionPayload;
  removeOrganizationLogo: IntrospectionPayload;
  revokeMember: IntrospectionPayload;
  role: IntrospectionPayload;
  roles: IntrospectionPayload;
  saveOnboarding: IntrospectionPayload;
  toggleAbilities: IntrospectionPayload;
  toggleAccessToModule: IntrospectionPayload;
  undefined: IntrospectionPayload;
  unlockOrganization: IntrospectionPayload;
  updateMaxMembers: IntrospectionPayload;
  updateOrganization: IntrospectionPayload;
  updateRole: IntrospectionPayload;
};

export type billingIntrospection = {
  __typename: "billingIntrospection";
  createOrResumeCheckout: IntrospectionPayload;
  finishCheckout: IntrospectionPayload;
  getConfig: IntrospectionPayload;
  getOrganizationBilling: IntrospectionPayload;
  getSubscriptionPlans: IntrospectionPayload;
  manageSubscription: IntrospectionPayload;
  updateCheckout: IntrospectionPayload;
  updateSubscription: IntrospectionPayload;
};

export type conversationIntrospection = {
  __typename: "conversationIntrospection";
  conversation: IntrospectionPayload;
  conversations: IntrospectionPayload;
  deleteConversation: IntrospectionPayload;
  deleteConversationMessage: IntrospectionPayload;
};

export type mainIntrospection = {
  __typename: "mainIntrospection";
  addStep: IntrospectionPayload;
  completeCalendarIntegration: IntrospectionPayload;
  completeSaleDraft: IntrospectionPayload;
  countCustomer: IntrospectionPayload;
  countOrders: IntrospectionPayload;
  countPeople: IntrospectionPayload;
  countSale: IntrospectionPayload;
  countTasks: IntrospectionPayload;
  countTimeTrackings: IntrospectionPayload;
  createContract: IntrospectionPayload;
  createCustomField: IntrospectionPayload;
  createCustomer: IntrospectionPayload;
  createEvent: IntrospectionPayload;
  createList: IntrospectionPayload;
  createOrder: IntrospectionPayload;
  createOrderTask: IntrospectionPayload;
  createPerson: IntrospectionPayload;
  createProduct: IntrospectionPayload;
  createProviderEvent: IntrospectionPayload;
  createRelation: IntrospectionPayload;
  createSale: IntrospectionPayload;
  createTask: IntrospectionPayload;
  createTimeTracking: IntrospectionPayload;
  createWorkflow: IntrospectionPayload;
  deleteContract: IntrospectionPayload;
  deleteCustomField: IntrospectionPayload;
  deleteCustomer: IntrospectionPayload;
  deleteCustomerStatus: IntrospectionPayload;
  deleteEvent: IntrospectionPayload;
  deleteEventType: IntrospectionPayload;
  deleteFile: IntrospectionPayload;
  deleteList: IntrospectionPayload;
  deleteManyCustomers: IntrospectionPayload;
  deleteManySales: IntrospectionPayload;
  deleteManyTasks: IntrospectionPayload;
  deleteManyTimeTrackings: IntrospectionPayload;
  deleteOrder: IntrospectionPayload;
  deleteOrderTask: IntrospectionPayload;
  deleteOrders: IntrospectionPayload;
  deletePeople: IntrospectionPayload;
  deletePerson: IntrospectionPayload;
  deleteProduct: IntrospectionPayload;
  deleteProviderEvent: IntrospectionPayload;
  deleteSale: IntrospectionPayload;
  deleteStep: IntrospectionPayload;
  deleteTask: IntrospectionPayload;
  deleteTimeTracking: IntrospectionPayload;
  deleteWorkflow: IntrospectionPayload;
  disableCalendarIntegration: IntrospectionPayload;
  downloadFile: IntrospectionPayload;
  enableCalendarIntegration: IntrospectionPayload;
  exportManyCustomers: IntrospectionPayload;
  exportManyOrders: IntrospectionPayload;
  exportManyPeople: IntrospectionPayload;
  exportManySales: IntrospectionPayload;
  exportManyTasks: IntrospectionPayload;
  exportManyTimeTrackings: IntrospectionPayload;
  file: IntrospectionPayload;
  fileUrl: IntrospectionPayload;
  files: IntrospectionPayload;
  getContract: IntrospectionPayload;
  getCustomer: IntrospectionPayload;
  getCustomerRelations: IntrospectionPayload;
  getEvent: IntrospectionPayload;
  getIntegratedCalendarAccounts: IntrospectionPayload;
  getList: IntrospectionPayload;
  getLists: IntrospectionPayload;
  getManyContracts: IntrospectionPayload;
  getManyCustomers: IntrospectionPayload;
  getManyEvents: IntrospectionPayload;
  getManyLists: IntrospectionPayload;
  getManyOrderTasks: IntrospectionPayload;
  getManyPeople: IntrospectionPayload;
  getManyProducts: IntrospectionPayload;
  getManySales: IntrospectionPayload;
  getManySteps: IntrospectionPayload;
  getManyTasks: IntrospectionPayload;
  getManyTimeTrackings: IntrospectionPayload;
  getOrderTask: IntrospectionPayload;
  getPerson: IntrospectionPayload;
  getPossibleRelations: IntrospectionPayload;
  getProduct: IntrospectionPayload;
  getProviderEvent: IntrospectionPayload;
  getProviderManyEvents: IntrospectionPayload;
  getSale: IntrospectionPayload;
  getSections: IntrospectionPayload;
  getStep: IntrospectionPayload;
  getTask: IntrospectionPayload;
  getTimeTracking: IntrospectionPayload;
  getWorkflow: IntrospectionPayload;
  listWorkflows: IntrospectionPayload;
  manuallySignSale: IntrospectionPayload;
  moveCustomField: IntrospectionPayload;
  moveOrderTask: IntrospectionPayload;
  moveProduct: IntrospectionPayload;
  moveTask: IntrospectionPayload;
  onCreateOrder: IntrospectionPayload;
  onDeleteOrder: IntrospectionPayload;
  onWonSale: IntrospectionPayload;
  order: IntrospectionPayload;
  orders: IntrospectionPayload;
  removeRelation: IntrospectionPayload;
  requestSignature: IntrospectionPayload;
  sumTimeTrackings: IntrospectionPayload;
  toggleListRecord: IntrospectionPayload;
  togglePersonFavorite: IntrospectionPayload;
  undefined: IntrospectionPayload;
  updateContract: IntrospectionPayload;
  updateCustomField: IntrospectionPayload;
  updateCustomer: IntrospectionPayload;
  updateCustomerAssigned: IntrospectionPayload;
  updateCustomerStatuses: IntrospectionPayload;
  updateEvent: IntrospectionPayload;
  updateEventTypes: IntrospectionPayload;
  updateList: IntrospectionPayload;
  updateOrder: IntrospectionPayload;
  updateOrderStatus: IntrospectionPayload;
  updateOrderTask: IntrospectionPayload;
  updateOrderTasks: IntrospectionPayload;
  updatePerson: IntrospectionPayload;
  updateProduct: IntrospectionPayload;
  updateProviderEvent: IntrospectionPayload;
  updateSale: IntrospectionPayload;
  updateSaleStatus: IntrospectionPayload;
  updateStep: IntrospectionPayload;
  updateTask: IntrospectionPayload;
  updateTimeTracking: IntrospectionPayload;
  updateWorkflow: IntrospectionPayload;
  upsertSaleDraft: IntrospectionPayload;
  url: IntrospectionPayload;
};

export type oidcIntrospection = {
  __typename: "oidcIntrospection";
  requestAccess: IntrospectionPayload;
};
